import styled from "styled-components";
import React, { useState, useEffect } from 'react';
import QuantityStepper from '../components/QuantityStepper';
import ColorSelector from '../components/ColorSelector';
import ImageContext from '../components/ImageContext';

import { createGlobalStyle } from "styled-components";
import { useNavigate } from 'react-router-dom';
import { HashLink } from "react-router-hash-link";
import { setFbc, setFbp, sendFbEvent, trackEvent, createEventId } from '../tools/FbTools';
import { useLocation } from 'react-router-dom';


const PurchasePage = (props) => {
  const [quantity, setQuantity] = useState(1);
  const [color, setColor] = useState('white');

  const totalPrice = 149 * quantity;

  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    navigate(`/${location.search}`);
  };

  useEffect(() => {
    console.log('Page loaded! Executing code...');
    setFbp();
    setFbc(location);
    console.log("purchase page loaded...")
    // sendFbEvent('ViewContent', location, {page: '/purchase'});
    const eventName = 'ViewContent';
    const eventId = createEventId(eventName);
    const customParams = {page: '/purchase'};
    trackEvent(eventName, customParams, eventId);
    sendFbEvent(eventName, location, customParams, eventId);
  }, []);

  const search = location.search;
  const params = search + (search ? '&' : '?') + 'quantity=' + quantity + '&price=' + totalPrice + '&currency=' + 'USD';

  return (
    <ImageContext.Consumer>
        {
            imgCtx => (
              <HeroContainer>
                <GlobalStyle2 />
                <CloseButton onClick={handleClose}>X</CloseButton>
                <ContentContainer>
                  <ImageContainer>
                    <img src={imgCtx.getFirst("senzys.png")} alt="SenzyBee" />
                  </ImageContainer>

                  <SelectorsContainer>
                      <div style={{margin: "0px 0px 0px 0px", padding: "10px"}}>
                          <h1>You are one touch away from your own SenzyBee</h1>
                          <h2>Bridge the gap of distance and feel the touch of your loved ones, wherever they are.</h2>
                          <div style={{display: "flex", flexFlow: "row", justifyContent: "space-between"}}>
                              <div>
                                  <h2>Set Quantity</h2>
                                  <QuantityStepper quantity={quantity} onQuantityChange={setQuantity} />
                              </div>
                              
                              <div>
                                  <h2>Choose Color</h2>
                                  <ColorSelector selectedColor={color} onColorChange={setColor} />
                              </div>
                          </div>
                          <hr style={{ width: '100%' }} />
                          <h2>Total Price: USD {totalPrice}</h2>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                            <div style={{ marginTop: '1rem' }}>
                              <HashLink to={'/' + search} style={{fontSize: '1.5rem', textDecoration: 'none', color: 'blue', alignSelf: 'flex-end' }}>Learn more...</HashLink>
                            </div>
                            <HashLink to={'/thankyou' + params} style={{ textDecoration: 'none' }}>
                              <button style={{ cursor: 'pointer' }}>Proceed to Payment</button>
                            </HashLink>
                          </div>  
                      </div>
                  </SelectorsContainer>
                </ContentContainer>
              </HeroContainer>
              )
        }
    </ImageContext.Consumer>
  );
};


const GlobalStyle2 = createGlobalStyle`
html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

* {
    outline: none;
}

body, div, span, h1,h2,h3,h4,h5,h6, p, ul, li {
    font-family: "Soleil", sans-serif;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  max-height: 100vh;
  overflow: hidden;
  background: #e5ded4;
}
`;

const HeroContainer = styled.div`
  background: #e5ded4;
  margin-top: 5.6rem;
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  @media (min-width: 768px) {
    flex-flow: row wrap;
  }
`;

const ContentContainer = styled.div`
  background: #e5ded4;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
  }

  @media (min-width: 768px) {
    width: 50%;
  }
`;

const SelectorsContainer = styled.div`
  width: 100%;
//   padding: 20px;

  @media (min-width: 768px) {
    width: 50%;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem; // 10px could be too close to the edge, adjusting for aesthetics
  right: 1rem;
  border: 0;
  background: transparent;
  cursor: pointer;
  font-size: 1.5rem; // Making the button a bit larger for better user experience
  z-index: 10; // Ensure it's above other elements
`;

export default PurchasePage;
