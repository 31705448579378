// Import dependencies
import styled from "styled-components";
import { useEffect } from "react";

const SecondContentSection = () => {
  
  
  const getOpacity = (pos, start, end) => {
    if (pos < start) {
      return 0;
    } else if (pos > end) {
      return 15;
    } else {
      var iv = end - start;
      return ((pos - start) / iv) * 15;
    }

  }

  useEffect(() => {
    document.addEventListener("scroll", () => {
      var secondElem = document.getElementById("secondContent");
      if (secondElem) {
        var second10 = secondElem.offsetHeight * 0.1;
        var second60 = secondElem.offsetHeight * 0.6;

      }
      
      var nobodycanElem = document.getElementById("nobodycan");
      if (nobodycanElem) {
        var nobodycanTop = nobodycanElem.getBoundingClientRect().top;
      }
      

      var buthereElem = document.getElementById("buthere");
      if (buthereElem) {
        var buthereTop = buthereElem.getBoundingClientRect().top;
        var opac = getOpacity(nobodycanTop, second10, second60);
        nobodycanElem.style.filter = `blur(${opac}px)`

        var opac2 = getOpacity(buthereTop, second10, second60);
        buthereElem.style.filter = `blur(${opac2}px)`
      }
      

      

    })


  }, []);

  return (
    <Content id="secondContent">
        <div id="nobodycan" className="left">Nothing can substitute his touch…</div>

        <div id="buthere" className="right">
          …but there is a way to rekindle the feeling in your heart.
        </div>
    </Content>
  );
};

const Content = styled.div`
  position: relative;
  font-size: 4.8rem;

  color: #304972;
  padding: 8.5rem 3rem;
  font-weight: 300;
  line-height: 110%;
  text-transform: uppercase;
  box-sizing: border-box;
  z-index: 0;
  background: #fff;
  
  height:100vh;

  display: flex;
  display: -webkit-flex;
  
  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: center;
  -webkit-justify-content: center;

  @media (max-width: 768px) {
    font-size: 3.6rem;
    padding: 14.8rem 2rem;
  }

  .right {
    text-align: right;
    margin-top: 7.5rem;
    filter: blur(15px);
  }
`;

export default SecondContentSection;
