import styled from "styled-components";
import React, { useEffect } from 'react';
import MauticFormPurchase from '../sections/MauticForm_purchase';
import ImageContext from '../components/ImageContext';
import { createGlobalStyle } from "styled-components";
import { useNavigate } from 'react-router-dom';
import { setFbc, setFbp, sendFbEvent, cleanUrl, trackEvent, createEventId } from '../tools/FbTools';
import { useLocation } from 'react-router-dom';

const ThankYouPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const quantity = queryParams.get('quantity');
  const price = queryParams.get('price');
  const currency = queryParams.get('currency');
  const cleanSearch = cleanUrl(location.search);
  console.log('cleanSearch: ', cleanSearch);

  const handleClose = () => {
    navigate(`/?${cleanSearch}`);
  };

  useEffect(() => {
    console.log('Page loaded! Executing code...');
    setFbp();
    setFbc(location);
    // sendFbEvent('Purchase', location, {
    //   value: price,
    //   currency: currency,
    //   quantity: quantity
    // });

    const eventName = 'Purchase';
    const customParams = {
      value: price,
      currency: currency,
      quantity: quantity
    }
    const eventId = createEventId(eventName);
    trackEvent(eventName, customParams, eventId);
    sendFbEvent(eventName, location, customParams, eventId);

  }, []);

  return (
    <ImageContext.Consumer>
        {
            imgCtx => (
              <HeroContainer>
                <GlobalStyle2 />
                <CloseButton onClick={handleClose}>X</CloseButton>
                <ContentContainer>
                  <ImageContainer>
                    <img src={imgCtx.getFirst("senzys.png")} alt="SenzyBee" />
                  </ImageContainer>
                  <SelectorsContainer>
                      <div style={{padding: "10px"}}>
                          <h1>Sorry, we are out of stock!</h1>
                          <h2>We are making something even more touching. </h2>
                          <h2>Sign up, be the first to be informed and get a 15% discount.</h2>
                          <MauticFormPurchase />
                      </div>
                  </SelectorsContainer>
                </ContentContainer>
              </HeroContainer>
            )
        }
    </ImageContext.Consumer>
  );
};


const GlobalStyle2 = createGlobalStyle`
html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

* {
    outline: none;
}

body, div, span, h1,h2,h3,h4,h5,h6, p, ul, li {
    font-family: "Soleil", sans-serif;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  max-height: 100vh;
  overflow: hidden;
  background: #e5ded4;
}
`;

const HeroContainer = styled.div`
  background: #e5ded4;
  margin-top: 5.6rem;
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  @media (min-width: 768px) {
    flex-flow: row wrap;
  }
`;

const ContentContainer = styled.div`
  background: #e5ded4;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
  }

  @media (min-width: 768px) {
    width: 50%;
  }
`;

const SelectorsContainer = styled.div`
  width: 100%;
//   padding: 20px;

  @media (min-width: 768px) {
    width: 50%;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem; // 10px could be too close to the edge, adjusting for aesthetics
  right: 1rem;
  border: 0;
  background: transparent;
  cursor: pointer;
  font-size: 1.5rem; // Making the button a bit larger for better user experience
  z-index: 10; // Ensure it's above other elements
`;

export default ThankYouPage;
