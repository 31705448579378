import styled from "styled-components";

const QuantityStepper = ({ quantity, onQuantityChange }) => {
    const handleIncrement = () => {
      onQuantityChange(quantity + 1);
    };
  
    const handleDecrement = () => {
      if (quantity > 1) {
        onQuantityChange(quantity - 1);
      }
    };
  
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <button onClick={handleDecrement} style={{ marginRight: '10px' }}>-</button>
        <span style={{ marginRight: '10px', fontSize: '20px' }}>{quantity}</span>
        <button onClick={handleIncrement}>+</button>
        </div>
    //   <div className="stepper">
    //     <button onClick={handleDecrement}>-</button>
    //     <div className="steppercount">{quantity}</div>
    //     <button onClick={handleIncrement}>+</button>
    //   </div>
    );
  };


  const StepperContainer = styled.div`
  margin-top: 5.6rem;
  display: flex;
  flex-flow: row wrap;

  @media (max-width: 768px) {
    margin-top: 2.5rem;
  }

  .text {
    width: 57%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .stepper {
    display: flex;
    flex-direction: row;
    align-items: justify-content;
    
  }

  .steppercount {
    font-size: 150px;
  }

  .button {
    border-radius: 100px;
    font-size: 1.6rem;
    background: transparent;
    font-weight: 400;
    padding: 1.5rem 3rem;
    cursor: pointer;
    transition: all 0.5s ease;
    margin: 0px 100px 0px 100px;

    @media (max-width:768px) {

        padding: 1rem 1.5rem;
    }
  }

`;


  
  export default QuantityStepper;