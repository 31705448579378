// Import dependencies
import styled, { keyframes } from "styled-components";
import { useEffect, useState } from "react";
import ImageContext from '../../components/ImageContext';

// Components
import FeatureContents from "./FeatureContents";
import FeatureSingle from "./FeatureSingle";

const FeatureContainer = () => {
  const [featureDatas] = useState(FeatureContents());
  let featNum = featureDatas.length
  // const [currentfeature, setIndex] = useState(featureDatas[0]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    let mobilFeature = document
      .getElementById("mobil-feature")
      .getElementsByTagName("div")[0];
    mobilFeature.classList.add("anim");
    setTimeout(() => {
      mobilFeature.classList.remove("anim");
    }, 1000);
  }, [index]);

  const FeatureSlider = (direction) => {
    if (direction === "back") {
      if ((index - 1) % featNum === -1) {
        setIndex(featNum - 1);
      } else {
        setIndex(
          (index - 1) % featNum
        );
      }
    } else {
      setIndex((index + 1) % featNum);
    }
  };

  return (
    <ImageContext.Consumer>
      {imgCtx => {
        console.log("Fetching features... ");
        var featureContents = FeatureContents(imgCtx);
        // console.log("Fetched contents: " + JSON.stringify(featureContents));

        return (
          <StyledFeatureContainer>
            <div className="desktop-box">
              {featureContents.map((feature) => (
                <FeatureSingle
                  key={feature.key}
                  featureDatas={feature}
                  // currentFeature={currentfeature}
                  // currentFeature={featureContents[0]}
                />
              ))}
            </div>

            <div className="mobile-box">
              <Ticker src={imgCtx.getLast("feature-prev.svg")} onClick={() => FeatureSlider("back")} />

              <div id="mobil-feature">
                <FeatureSingle featureDatas={featureContents[index]} />
              </div>

              <Ticker src={imgCtx.getLast("feature-next.svg")} onClick={() => FeatureSlider("forward")} />
            </div>
          </StyledFeatureContainer>)
      }}
      </ImageContext.Consumer>
  );
};

const Intro = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;

const StyledFeatureContainer = styled.div`
  width: 100%;

  .desktop-box {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;


    display: -webkit-flex;
    webkit-flex-wrap: wrap;
    webkit-align-items: center;
    webkit-justify-content: flex-start;
    webkit-flex-direction: row;


    gap: 19px;
    z-index: 4;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .anim {
    animation: ${Intro} 1s ease forwards;
  }

  .mobile-box {
    overflow: hidden;
    position: relative;
    margin: 6rem 0rem;
    display: none;

    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

const Ticker = styled.img`
  height: 43px;
  width: 43px;

  @media (max-width: 768px) {
    height: 32px;
    width: 32px;
  }
`;

export default FeatureContainer;
