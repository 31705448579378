// Import dependencies
import styled from "styled-components";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImageContext from '../components/ImageContext';

import React from 'react';
import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';
 



const Footer = () => {
  const [openModal, setOpenModal] = useState(false);
  const [content, setContent] = useState("");

  const showAndFetch = (isPrivacy) => {
    var src
    if (isPrivacy) {
      src = 'privacy-policy.html';
    } else {
      src = 'terms-of-service.html';
    }

    fetch(
        src
        // ,
        // {
        //   headers : { 
        //       'Content-Type': 'text/html'
        //   }
        // }
    )
    .then( r => r.text() )
    .then( t => {
      console.log("doc response... " + t);
      // console.log("doc response... " + JSON.stringify(t));
      setContent(t);
      setOpenModal(true);
    })

    // .then((response) => {
    //     console.log("doc response... " + JSON.stringify(response));
    //     setContent(response);
    //     setOpenModal(true);
    //     // return response.json();
    // });


  }


  const privacyOpen = () => {
    console.log('privacyOpen');
    showAndFetch(true);
  }

  const tosOpen = () => {
    console.log('tosOpen')
    showAndFetch(false);
  }

  const closeModal = () => {
    setOpenModal(false);
  }


  useEffect(() => {
    let date = new Date();
    document.getElementById("year").innerHTML = date.getFullYear();
  });

  const contentStyle = { background: '#ffffff', width: '100vw', padding: "10px", overflow: 'scroll' };
  const overlayStyle = { background: 'rgba(0,0,0,0.5)', overflow: 'scroll' };

  
  return (
    <ImageContext.Consumer>
      {imgCtx => {
        return (
            <StyledFooter id="contact">
              <UpperHalf>
                <div className="righter">
                  <div className="link-container">
                      <div className="column">
                        <img src={imgCtx.getLast("senzybee-header-logo-blue.svg")} alt="SenzyBee Relationship logo blue" />
                        <div className="socials">
                          <a href="https://www.facebook.com/SenzyBee"><img inline src={imgCtx.getLast("logo-facebook.svg")} width="30px" alt="SenzyBee Facebook Page" border="0"></img></a>
                          <a href="https://www.instagram.com/senzybee" style={{marginLeft: "25px", marginRight:"25px"}}><img inline src={imgCtx.getLast("logo-instagram.svg")} width="30px" alt="SenzyBee Instagram Page" border="0"></img></a>
                          <a href="https://twitter.com/SenzyBee"><img inline src={imgCtx.getLast("logo-twitter.svg")} width="30px" alt="SenzyBee Twitter Page" border="0" style={{color: "#304972"}}></img></a>
                        </div>
                      </div>
                  </div>
                </div>
              </UpperHalf>
              <div className="divider"></div>
              <BottomHalf>
                <ul>
                    <li>
                      <Link to="" onClick={tosOpen}>Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="" onClick={privacyOpen}>Privacy Policy</Link>
                    </li>
                </ul>
              </BottomHalf>
              <div>
                <Popup open={openModal} position="right center" closeOnDocumentClick modal onClose={closeModal}
                      {...{ contentStyle, overlayStyle }}>
                  <div style={{overflow: 'scroll'}}>
                    <div dangerouslySetInnerHTML={{
                      __html: `${content}`}}  style={{overflow: 'scroll'}}>
                    </div>
                    <button style={{float: "right"}} onClick={closeModal} >Close</button>
                  </div>
                  
                </Popup>

              </div>
              <div className="copyright">
                  ©<span id="year">2021</span> SenzyBee. All Right Reserved.
                </div>
  
            </StyledFooter>)
      }}
      </ImageContext.Consumer>
  );
};

const StyledFooter = styled.div`
  background: #f6f3ef;
  padding: 0rem 9rem;

  .close-button {
    float: right;
  }

  .popup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    width: 100vw;
    height: 100vh;
    padding: 5px;
  }

  @media (max-width: 768px) {
    padding: 0.5rem 3rem 1.5rem 2rem;
  }

  .socials {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    
    align-items: center;
    width: 30vw;

    @media (max-width: 768px) {
      width: 50vw;
      // padding: 0.5rem 3rem 1.5rem 2rem;
    }
  }

  .copyright1 {
    font-size: 1.4rem;
    color: #beb4a0;
    line-height: 2.5rem;
    margin-left: auto;
    margin-right: 0px;

    font-size: 1.2rem;
    text-align: center;
    line-height: 1.8rem;
    margin-top: 0.5rem;

    @media (max-width: 768px) {
      font-size: 1.2rem;
      text-align: center;
      line-height: 1.8rem;
      margin-top: 0.5rem;
    }

    a {
      color: #beb4a0;
      font-weight: 500;
    }
  }

  .copyright {
    font-size: 1.4rem;
    color: #beb4a0;
    line-height: 2.5rem;

    font-size: 1.2rem;
      text-align: center;
      line-height: 1.8rem;
      margin-top: 0.5rem;

    @media (max-width: 768px) {
      font-size: 1.2rem;
      text-align: center;
      line-height: 1.8rem;
      margin-top: 0.5rem;
    }

    a {
      color: #beb4a0;
      font-weight: 500;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background: #e3dfdb;
    margin: 3.75rem 0rem 5rem;

    @media (max-width: 768px) {
      margin: 5rem 0 2.5rem;
    }
  }
`;

const UpperHalf = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  webkit-flex-direction: column;
  align-items: flex-start;

  flex-flow: column wrap;

  @media (max-width: 768px) {
    flex-flow: column wrap;
  }

  .righter {
    display: flex;
    gap: 11vw;
    width: 100%;
    flex-flow: column wrap;

    gap: 5rem;

    @media (max-width: 768px) {
      
      gap: 5rem;
    }
  }

  .link-container {
    display: flex;
    justify-content: space-between;

    margin-top: 5rem;

    @media (max-width: 768px) {
      margin-top: 5rem;
    }

    .column {
      display: flex;
      flex-flow: row wrap;

      justify-content: space-between;
      width: 100%;
      color: #ff0000;
    }
  }
`;

const BottomHalf = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: column-reverse wrap;

  @media (max-width: 768px) {
    
  }
  .copyright {
    font-size: 1.4rem;
    color: #beb4a0;
    line-height: 2.5rem;

    @media (max-width: 768px) {
      font-size: 1.2rem;
      text-align: center;
      line-height: 1.8rem;
      margin-top: 0.5rem;
    }

    a {
      color: #beb4a0;
      font-weight: 500;
    }
  }

  ul {
    display: flex;
    flex-flow: row nowrap;
    list-style-type: none;
    padding: 0;
    justify-content: center;

    li {
      font-size: 1.4rem;
      line-height: 1.8rem;
      text-align: center;
      margin-left: 1.5rem;

      &:first-of-type {
        margin-left: 0;
      }

      @media (max-width: 768px) {
        font-size: 1.3rem;
        width: fit-content;
      }

      a {
        color: #beb4a0;
      }
    }
  }
`;

export default Footer;
