import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

* {
    outline: none;
}

body, div, span, h1,h2,h3,h4,h5,h6, p, ul, li {
    font-family: "Soleil", sans-serif;
}

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    max-height: 100vh;
    overflow: hidden;
    background: linear-gradient(100.78deg, #2d4978 7.67%, #021226 147.31%);
}

button {
    border-radius: 100px;
    font-size: 1.6rem;
    background: transparent;
    font-weight: 400;
    padding: 1.5rem 3rem;
    cursor: pointer;
    transition: all 0.5s ease;

    @media (max-width:768px) {

        padding: 1rem 1.5rem;
    }

}

.kickstarter {
    border: 2px solid #304972;
    color: #304972;
    text-transform: uppercase;

    &:hover {
      background: #304972;
      color: #e5ded4;
    }

    @media (max-width:768px) {
        padding: 1rem 1.5rem;
        
    }
  }

  input {
      border-radius: 0;
  }

  .text-link {
      border: none;
      width: 30%;
      font-size: 15px;
      color: #fff;
      padding: 0;
      text-decoration: underline;
      transition: all 0.3s ease;

      &:hover {
        opacity: 0.85;
      }
    }

    .loader {
        display: block;
    }

    .loader-hide {
        animation: fadeOut 2s forwards;
        display: none;
    }

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            
        }
    }

    
`;

export default GlobalStyle;
