// Import dependencies
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";
import ImageContext from '../components/ImageContext';
import { useLocation } from 'react-router-dom';


const MobilView = ({ MenuVis, setMenuVis }) => {
  const location = useLocation();
  return (
    <ImageContext.Consumer>
      {imgCtx => {
        // console.log("Re-render... firstReady: " + imgCtx.state.firstReady);
        return (
          <MobilMenu
            style={
              MenuVis
                ? { opacity: 1, transform: "translateX(0)" }
                : { opacity: 0, transform: "translate(-100%)" }
            }
          >
            <div className="mobil-header">
              <div className="action-box">
                <img
                  src={imgCtx.getFirst("close.svg")}
                  className="close-menu"
                  alt="close icon"
                  onClick={() => setMenuVis(!MenuVis)}
                />
              </div>
              <img src={imgCtx.getFirst("senzybee-header-logo-white.svg")} alt="mobile SenzyBee logo white" />
            </div>
            <div className="mobil logotext2">
                <img src={imgCtx.getFirst("SenzyBee.svg")} alt="mobile SenzyBee text" />
            </div>

            <div className="mobil-links">
                {/* <a href="/blog/blog/index.html">Blog</a> */}
                <a href="https://blog.senzybee.com">Blog</a>
                <HashLink
                  to="/#features"
                  onClick={() => {
                    setMenuVis(!MenuVis);
                  }}
                >
                  Features
                </HashLink>
                {/* <HashLink to="/purchase">Buy</HashLink> */}
                <HashLink to={'/purchase' + location.search}>Buy</HashLink>
                <HashLink
                  to="/#contact"
                  onClick={() => {
                    setMenuVis(!MenuVis);
                  }}
                >
                  Contact
                </HashLink>
            </div>

            <div></div>
          </MobilMenu>)}
      }
    </ImageContext.Consumer>
  );
};

const MobilMenu = styled.div`
  display: none;
  flex-flow: column wrap;
  height: 100vh;
  position: fixed;
  top: 0;
  
  left: 0;

  width: 100%;
  background: linear-gradient(100.78deg, #2d4978 7.67%, #021226 147.31%);
  z-index: 10;
  padding: 3rem 0rem;
  align-items: center;
  transition: all 0.5s ease;
  opacity: 0;
  overflow: hidden;

  .logotext2 {
    position: absolute;
    top: 10px;
    width: 100%;
    margin-left: -20px;
    background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 768px) {
    display: flex;
  }

  .mobil-header {
    display: flex;
    align-items: center;
    z-index: 10;
    justify-content: space-between;
    width: calc(100% - 40px);
  }

  .kickstarter {
    border: 2px solid #fff;
    color: #fff;
    padding: 1rem 1.5rem;
    margin-right: 2rem;

    &:hover {
      background: #fff;
      color: #304972;
    }
  }

  .action-box {
    display: flex;
    align-content: center;
  }

  .mobil-links {
    font-size: 4.8rem;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    align-self: flex-start;
    margin-left: 2rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    a {
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      line-height: 100%;
      font-weight: 600;
      transition: all 0.5s ease;

      &:hover {
        color: #000;
      }
    }
  }
`;

export default MobilView;
