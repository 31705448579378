// Import dependencies
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";

// Components
import MobilView from "./MobilView";
import ImageContext from '../components/ImageContext';
import PurchaseButtonIcon from "../components/PurchaseButtonIcon";
import { useLocation } from 'react-router-dom';


const Header = ({ MenuVis, setMenuVis }) => {
  const location = useLocation();

  return (
    <ImageContext.Consumer>
      {imgCtx => {
        return (
          <StyledHeader>
            <img
              src={imgCtx.getFirst("hamburger.svg")}
              className="open-menu"
              onClick={() => setMenuVis(!MenuVis)}
              alt="hamburger icon"
            />
            
            <img className="mobil logotext" src={imgCtx.getFirst("SenzyBee.svg")} alt="mobile SenzyBee text" />
            <div className="desktop">
              <ul>
                  <li>
                    <a href="https://blog.senzybee.com">Blog</a>
                    {/* <a href="/blog/blog/index.html">Blog</a> */}
                  </li>
                  <li>
                    <HashLink to="/#features">Features</HashLink>
                  </li>
                  <li>
                    <HashLink to={'/purchase' + location.search}>Buy</HashLink>
                  </li>
                  <li>
                    <HashLink to="/#contact">Contact</HashLink>
                  </li>
              </ul>
            </div>
              <PurchaseButtonIcon/>
            <MobilView MenuVis={MenuVis} setMenuVis={setMenuVis} />
          </StyledHeader>
        )
      }
      }
      </ImageContext.Consumer>
  );
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.4rem 0rem;

  .desktop {
    @media (max-width: 768px) {
      display: none;
      visibility: hidden;
    }
  }

  .mobil {
    @media (min-width: 769px) {
      display: none;
      visibility: collapse;
    }
  }

  .logotext {
    width: 40%;
    height: auto;
  }

  .open-menu {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }

  ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 2px solid #304972;
    padding: 1.3rem 3rem;
    border-radius: 10px;
    // width: 25rem;
    box-sizing: border-box;

    @media (max-width: 768px) {
      display: none;
    }

    li {
      font-size: 1.6rem;
      text-transform: uppercase;
      font-style: normal;
      padding-left: 10px;
      padding-right: 10px;

      a {
        text-decoration: none;
        color: #304972;
        position: relative;

        &:after {
          content: "";
          height: 0px;
          transition: all 0.2s ease;
          opacity: 0;
          display: block;
          position: absolute;
          bottom: -14px;
          background: #304972;
          width: 100%;
          content: "";
        }

        &:hover:after {
          height: 4px;
          opacity: 1;
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }

  .open-mobil {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
export default Header;
