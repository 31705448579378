// Assets
import ellipse from "../../assets/png/ellipse.png";

function FeatureContents(imgCtx) {
  return [
    {
      image: (typeof(imgCtx) != "undefined") ? imgCtx.getLast("vibration.svg") : ellipse,
      title: "Next gen vibration",
      content:
        "Our algorithm simulates your touch using a very precise vibration model.",
      id: 1,
      key: "f1",
    },

    {
      image: (typeof(imgCtx) != "undefined") ? imgCtx.getLast("actuator.svg") : ellipse,
      title: "15 pcs Actuators",
      content:
        "Long life-span, high-precision actuators to provide accurate haptic (science of touch) feedback.",
      id: 2,
      key: "f2",
    },

    {
      image: (typeof(imgCtx) != "undefined") ? imgCtx.getLast("usbc.svg") : ellipse,
      title: "USB-C Connector",
      content: "For charging SenzyBee",
      id: 3,
      key: "f3",
    },

    {
      image: (typeof(imgCtx) != "undefined") ? imgCtx.getLast("touchpad.svg") : ellipse,
      title: "Capacitive Touch Pad",
      content:
        "Highly sensitive touchpad. Multi-touch support for up to 5 fingers. Ability to sense your slightest touch.",
      id: 4,
      key: "f4",
    },

    {
      image: (typeof(imgCtx) != "undefined") ? imgCtx.getLast("battery.svg") : ellipse,
      title: "Long Battery Life",
      content: "2000mAh battery can last for up to 8 hours intensive usage.",
      id: 5,
      key: "f5",
    },

    {
      image: (typeof(imgCtx) != "undefined") ? imgCtx.getLast("notification.svg") : ellipse,
      title: "Apps & Notifications",
      content:
        "Messenger app to transfer touch information. For iPhone and Android.",
      id: 6,
      key: "f6",
    },

    {
      image: (typeof(imgCtx) != "undefined") ? imgCtx.getLast("connection.svg") : ellipse,
      title: "Wi-Fi & Bluetooth LE",
      content:
        "BLE connects you to SenzyBee with your phone. Wi-Fi directly connects the Internet to your device.",
      id: 7,
      key: "f7",
    },

    {
      image: (typeof(imgCtx) != "undefined") ? imgCtx.getLast("design.svg") : ellipse,
      title: "Ergonomic Design",
      content: "The design your palm will love.",
      id: 8,
      key: "f8",
    },
  ];
}

export default FeatureContents;
