// Import dependencies
import styled from "styled-components";

const FirstContentSection = () => {
  return (
    <Content id="firstContent">
      What if the distance <br/>that separates you <br/>from your loved one <br/>magically
      disappeared?
    </Content>
  );
};

const Content = styled.div`
  font-size: 4.8rem;
  // font-size: 9.6rem;
  color: #304972;
  
  padding: 8.5rem 3rem;
  // padding: 0rem 0rem 14.2rem 2rem;

  font-weight: 300;
  line-height: 125%;
  text-transform: uppercase;
  width: 100%;
  box-sizing: border-box;
  margin-top: auto;
  margin-bottom: auto;
  background: #fff;

  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: center;
  -webkit-justify-content: center;

  min-height:100vh;

  @media (max-width: 768px) {
    font-size: 3.6rem;
    padding: 2rem 2rem 2rem;
    height: initial;
    width: 100%;
  }
`;

export default FirstContentSection;
