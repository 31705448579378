// Import dependencies
import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { sendFbEvent, trackEvent, createEventId } from '../tools/FbTools';
import { useLocation } from 'react-router-dom';

// Assets
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MauticFormPurchase = () => {
  const [videoKey, setVideoKey] = useState(false);
  const [src, setSrc] = useState('./weloveyou-landscape.mp4'); 
  const location = useLocation();
  
  function lightbox_open() {
    const handler = e => {
        console.log('media query: ' + e.matches)
        document.getElementById('light').style.display = 'block';
        document.getElementById('fade').style.display = 'block';
  
        if (e.matches) {
            console.log('Mobile matched...')
            setSrc('./weloveyou-portrait.mp4');
            setVideoKey(!videoKey);
            
        } else {
            console.log('Desktop matched...')
            setSrc('./weloveyou-landscape.mp4');
            setVideoKey(!videoKey);
        }

        var lightBoxVideo = document.getElementById('VisaChipCardVideo2');
        console.log('Placying...')

        lightBoxVideo.play();

        setTimeout(() => {
          lightbox_close()
        }, 5000); 
        
        console.log('lightbox_open...');
    };
    handler(window.matchMedia('(max-width: 768px)'));
  
  }
  
  function lightbox_close() {
    var lightBoxVideo = document.getElementById('VisaChipCardVideo2');
    let light = document.getElementById('light')
    let fade = document.getElementById('fade')
  
    if (light && fade && lightBoxVideo) {
      light.style.display = 'none';
      fade.style.display = 'none';
      lightBoxVideo.pause();
    }
  
    console.log('lightbox_close')  
  }

  function sendEmail() {
  
    var email = document.getElementById('mauticform_input_prelaunchoptin2_email').value
    console.log('Clicked...email: ' + email);
    const formData = new FormData()
    formData.append('mauticform[formId]', 3)
    formData.append('mauticform[messenger]', 1) // required  
    formData.append('mauticform[email]', email)
  
    fetch('https://mautic.senzybee.com/form/submit', {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: formData,
    })
    .then(response => response.text())
    .then(result => {
      console.log('The result: ' + result)
      console.log('The result: ' + JSON.stringify(result))
      if (result.includes('results')) {
        console.log('Thank you for a message');
        // sendFbEvent('Subscribe', location, {});
    
        const eventName = 'Subscribe';
        const eventId = createEventId(eventName);
        const customParams = {};
        trackEvent(eventName, customParams, eventId);
        sendFbEvent(eventName, location, customParams, eventId);


        lightbox_open();
      } else {
        console.log('Error');
        // sendFbEvent('ViewContent', location, {page: '/thankyou', text: 'Failed to subscribe! Internal error'});
        // sendFbEvent('PageView', location, {page: '/thankyou', text: 'Failed to subscribe! Internal error'});

        const eventName = 'ViewContent';
        const eventId = createEventId(eventName);
        const customParams = {page: '/thankyou', text: 'Failed to subscribe! Internal error'};
        trackEvent(eventName, customParams, eventId);
        sendFbEvent(eventName, location, customParams, eventId);
        toast.error('Failed to subscribe! Internal error');
      }
    })
    .catch(e => {
      console.log('Error happened...' + JSON.stringify(e))
      // sendFbEvent('ViewContent', location, {page: '/thankyou', text: 'Failed to subscribe! Internal error'});
      // sendFbEvent('PageView', location, {page: '/thankyou', text: 'Failed to subscribe! Internal error'});

      const eventName = 'ViewContent';
      const eventId = createEventId(eventName);
      const customParams = {page: '/thankyou', text: 'Failed to subscribe! Internal error'};
      trackEvent(eventName, customParams, eventId);
      sendFbEvent(eventName, location, customParams, eventId);
      
      toast.error('Failed to subscribe! Please check your Internet connection!');
    });
  }

  function Clip({ url }) {
    console.log('Clip, url: ' + url)
    const videoRef = useRef();
    const previousUrl = useRef(url);
  
    useEffect(() => {
      if (previousUrl.current !== url && videoRef.current) {
        console.log('useEffect, load...url: ', url)
        videoRef.current.load();
        previousUrl.current = url;
      }
    }, [url]);
  
    return (
      <video ref={videoRef} id='VisaChipCardVideo2' onClick={lightbox_close}
              preload='metadata'
              muted
              autoPlay
              playsInline
              >
        <source src={url} />
      </video>
    );
  }

  return (
    <MauticStyled>
      <div id='mauticform_wrapper_prelaunchoptin'>
      <div
        id='mauticform_prelaunchoptin_email'
        className='mauticform-row'>
            <label>
              <input id='mauticform_input_prelaunchoptin2_email' 
                 name='mauticform[email]' 
                 placeholder='Email address' 
                 className='mauticform-input' 
                 type='email'/>
            </label>
      </div>

      <button onClick={sendEmail} style={{float: 'right', marginTop: '10px'}}>
        Subscribe
      </button>
      <div id='fade' onClick={lightbox_close}></div>
        <div id='light'>
          <Clip url={src} />
        </div>
      <ToastContainer />
      </div>
    </MauticStyled>
  );
};

const MauticStyled = styled.div`
  width: 100%;
  font-weight: 300;

  #VisaChipCardVideo {
    width: 100%;
    height: 100%;
  }

  #fade {
    display: none;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 1001;
    -moz-opacity: 0.8;
    opacity: .80;
    filter: alpha(opacity=80);
  }

  #light {
    display: none;
    position: fixed
    ;
    opacity: 1.0;


    width: 100%;
    height: auto;
    // top: calc(100vh -height)/2;
    // bottom: auto;
    top: 0;
    bottom: 0;
    left: 0%;
    max-width: 100%;

    border: 2px solid #FFF;
    background: #FFF;
    z-index: 1002;
    overflow: visible;
  }


  .mautic-label {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 2.4rem;
    color: #304972;

    @media (max-width: 768px) {
      font-size: 1.8rem;
    }
  }
  .mauticform-row {
    display: block;
    margin-bottom: 20px;
    width: 80%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .mauticform-row.mauticform-required .mauticform-label:after {
    color: #e32;
    content: ' *';
    display: inline;
  }

  .mauticform-selectbox,
  .mauticform-input,
  .mauticform-textarea {
    width: 60%;
    padding: 0.5em 0.5em;
    border: 1px solid #ccc;
    background: #fff;
    box-shadow: 0px 0px 0px #fff inset;
    box-sizing: border-box;
  }

  # New
  .mauticform-button-wrapper {
    @media (max-width: 768px) {
      width: 100%;
    }
  }

`;

export default MauticFormPurchase;
