// Import dependencies
import styled from "styled-components";
import ImageContext from '../../components/ImageContext';
import StickyPersonal from '../../components/StickyPersonal';
import PurchaseButton from "../../components/PurchaseButton";

const PersonalTouch = () => {

  return (
    <ImageContext.Consumer>
      {imgCtx => {
        console.log("Re-render... blueReady: " + imgCtx.state.blueReady + ", flyReady: " + imgCtx.state.flyReady);
        return (
            <PersonalBlock id="personalBlock">
              <HeartImg src={imgCtx.getBlue("heart.svg")} alt="Heart for loving couple. Beating with SenzyBee" />
              <LeftCircle src={imgCtx.getBlue("left-circle.svg")} alt="Bubble for darlings happy with SenzyBee" />
              <RightCircle src={imgCtx.getBlue("right-circle.svg")} alt="Bubble for people in intimate relationship with Senzybee." />
              <div className="main-text" id="personaltext" >
                Your personal touchpad surprises
              </div>
              {/* <StickyPersonal 
                      top='#personaltext' 
                      innerZ={6} 

                      srcLeft={imgCtx.getBlue("left-arm.png")} 
                      srcRight={imgCtx.getBlue("right-arm.png")} 

                      range={80} 
                      freeze={20}
                      startPos={50}
                      >
              </StickyPersonal> */}
              <div className="text">
                <div className="subheadline">Touchpad magic</div>
                <div className="headline" id="bringsyou">
                  Bringing you closer together when you’re apart
                </div>
                <div className="content">
                  Sweep your fingers across the pad with a light brush or a quick tap
                  and give your soulmate goosebumps. You feel it together. 
                  <br/><br/>Caress your
                  partner through SenzyBee, give and feel the rhythms and closeness of
                  touch when you are apart.
                </div>
                <br/>
                  <br/>
                  <br/>
                  <br/>
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                  <PurchaseButton text="Buy Now" style={{color: "white"}}/>
                </div>
                
              </div>
              
              
            </PersonalBlock>
        )
      }}
      

    </ImageContext.Consumer>
    
  );
};

const PersonalBlock = styled.div`
  background: linear-gradient(100.78deg, #2d4978 7.67%, #021226 147.31%);
  padding: 25rem 11.9rem 17.5rem 9rem;
  position: relative;
  height: 176rem;
  box-sizing: border-box;
  overflow: hidden;
  
  @media (max-width: 768px) {
    padding: 10rem 0rem 5rem;
    height: 170vh;
  }

  .stickyStart {
    position: relative;
    top: -8vh;
  }

  .main-text {
    position: relative;
    font-size: 7.2rem;
    line-height: 110%;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
    color: #d8e0e3;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
      font-size: 3.6rem;
    }
  }

  .text {
    position: relative;
    margin-top: 50vh;
    // margin-right: 10rem;
    color: #fff;
    // float: right;
    width: 34vw;
    margin-left: auto;
      margin-right: auto;

    @media (max-width: 768px) {
      margin-top: 60vh;
      max-width: 25rem;
      width: 100%;
      // margin-right: 0;
      margin-right: auto;
      margin-left: auto;
    }

    .subheadline {
      font-size: 1.8rem;
      line-height: 110%;
      font-weight: 300;
      text-transform: uppercase;
      color: #d8e0e3;

      @media (max-width: 768px) {
        font-size: 1.3rem;
      }
    }

    .headline {
      font-size: 4.8rem;
      line-height: 110%;
      font-weight: 300;
      margin: 1.3rem 0;
      

      @media (max-width: 768px) {
        font-size: 2rem;
        margin: 1rem 0;
      }
    }

    .content {
      font-size: 2.4rem;
      font-weight: 300;

      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
    }

    button {
      color: #fff;
      border: 2px solid #fff;
      font-size: 1.6rem;
      text-transform: uppercase;
      margin-top: 2.5rem;
      float: right;

      &:hover {
        background: #fff;
        color: #304972;
      }
    }
  }
`;

const HeartImg = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;

  @media (max-width: 768px) {
    width: 55vw;
    height: auto;
  }
`;

const LeftCircle = styled.img`
  position: absolute;
  left: 0;
  top: 61vh;
  @media (max-width: 768px) {
    width: 36.25vw;
    height: auto;
    top: 36.5vh;
  }
`;

const RightCircle = styled.img`
  position: absolute;
  right: 0;
  top: 73vh;
  z-index: 0;

  @media (max-width: 768px) {
    width: 40vw;
    height: auto;
    top: 18.75vh;
  }
`;


export default PersonalTouch;
