import React, { useState } from 'react';
import ImageContext from './ImageContext';
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";
import { useLocation } from 'react-router-dom';

const PurchaseButtonIcon = (props) => {
  console.log("PurchaseButtonIcon...")
  const style = props.style || {color: "white", background: "#d4dde0"};
  const location = useLocation();

  return (
    <ImageContext.Consumer>
      {
        imgCtx => (
          <HashLink to={'/purchase' + location.search}>
            <StyledButton>
                <svg className="mobil" alt="alt SenzyBee logo blue" xmlns="http://www.w3.org/2000/svg" height="24" width="27" viewBox="0 0 576 512">
                  <path fill="#304972" d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
                </svg>
            </StyledButton>
          </HashLink>
        )
      }
    </ImageContext.Consumer>
  );
  
};

const StyledButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.4rem 0rem;

  .desktop {
    @media (max-width: 768px) {
      display: none;
      visibility: hidden;
    }
  }
  
  button {
    background: transparent;
    border: 0px solid #fff;
    @media (max-width:768px) {

        padding: 1rem 1.5rem;
    }

  }

  .mobil {
    @media (min-width: 769px) {
      display: none;
      visibility: collapse;
    }
  }

  .logotext {
    width: 40%;
    height: auto;
  }

  .open-menu {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;

export default PurchaseButtonIcon;