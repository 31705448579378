// Import dependencies
import styled from "styled-components";
import ImageContext from '../../components/ImageContext';

const Sizes = () => {
  return (
    <ImageContext.Consumer>
      {imgCtx => {
        // console.log("Re-render... lastReady: " + imgCtx.state.lastReady);
        return (
            <StyledSizes>
              <img
                src={(window.innerWidth > 768) ? imgCtx.getLast("senzy-size-desktop.svg") : imgCtx.getLast("sizes-mobil.svg")} style={{width:'100%'}}
                alt="The World's first remote touch device SenzyBee dimensions"
              />
              <ConfigList>
                <li>Two indicator eyes</li>
                <li>Config button</li>
                <li>Home button</li>
                <li>Touch pad</li>
                <li>
                  Battery led <br /> (red, green)
                </li>
              </ConfigList>
            </StyledSizes>)
      }}
    </ImageContext.Consumer>
  );
};

const StyledSizes = styled.div`
  padding: 13.3rem 1rem 14.3rem 3.5rem;
  display: flex;
  justify-content: space-between;
  gap: 8.4rem;

  @media (max-width: 768px) {
    padding: 8rem 2rem 5rem 0rem;
  }
`;

const ConfigList = styled.ul`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  gap: 1.2rem;

  li {
    color: #304972;
    font-size: 2.4rem;
    font-weight: 300;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export default Sizes;
