// Import dependencies
import styled from "styled-components";
// Assets
import SenzyVideo from "../assets/video/SenzyBee.mp4";

  const ExplainerVideoSection = () => {
    return (
            <Video id="explainervideo">
            <div
              dangerouslySetInnerHTML={{
                __html: `
            <video
            height="100%"
            width="100%"
            preload="metadata"
              loop
              muted
              autoplay
              playsinline
              src="${SenzyVideo}"
            />,
          `,
              }}
            ></div>
          </Video>
    );
  };

const Video = styled.div`
  background: #000;
  background-size: cover;
  height: auto;
  z-index: 4;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  // width: 100vw;

`;

export default ExplainerVideoSection;
