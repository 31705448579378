// Import dependencies
import styled from "styled-components";
import ImageContext from '../components/ImageContext';
// Assets
import DayWithout from "../assets/video/DayWithout.mp4";

const VideoSection = () => {
  return (
    <ImageContext.Consumer>
      {imgCtx => {
        // console.log("Re-render... lastReady: " + imgCtx.state.lastReady);
        return (
          <Video id="video">
            <video height="100%" width="100%" controls poster={imgCtx.getLast("video-placeholder_1.jpg")}>
              <source src={DayWithout} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Video>)
      }}
    </ImageContext.Consumer>
  );
};

const Video = styled.div`
  background: #000;
  background-size: cover;
  height: auto;
  z-index: 4;
  box-sizing: border-box;
  position: relative;
  width: 100%;

  // video {
  //   position: absolute;
  //   #min-height: 23.5rem;
  //   height: auto;
  //   width: 100%;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }

  // @media (max-width: 768px) {
  //   height: 100%;
  //   width: 100%;
  //   #min-height: 21rem;
  // }
`;

export default VideoSection;
