// Import dependencies
import styled from "styled-components";

const FeatureSingle = ({ featureDatas }) => {
  // console.log("Feature data: " + JSON.stringify(featureDatas));
  return (
    <FeatureStyled>
      <img key={featureDatas.key} 
      
          src={featureDatas.image}
          alt={featureDatas.title} />
      <div className="title">{featureDatas.title}</div>
      <div className="content">{featureDatas.content}</div>
    </FeatureStyled>
  );
};

const FeatureStyled = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  flex-basis: 23%;
  margin-top: 10rem;

  @media (max-width: 768px) {
    min-width: 70%;
    flex-basis: 70%;
    text-align: center;
    margin-top: 3rem;
  }

  .title {
    font-size: 2.5rem;
    line-height: 110%;
    font-weight: 300;
    color: #304972;
    margin: 3rem 0rem 1.5rem;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  .content {
    font-size: 1.6rem;
    color: #304972;
    text-align: center;
    height: 6.9rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
`;

export default FeatureSingle;
