// Import dependencies
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";
import ImageContext from '../../components/ImageContext';

const Resonate = () => {
  return (
    <ImageContext.Consumer>
      {imgCtx => {
        return(
          <ResonateContainer>
            <LeftCircle src={imgCtx.getBlue("border-circle-left.svg")} alt="Border circle SenzyBee bubble"/>
            <RightCircle src={imgCtx.getBlue("border-circle-right.svg")} alt="Border circle SenzyBee bubbling" />
            <Hands src={imgCtx.getBlue("hands.png")}  alt="Caressing hands of long distance couple having SenzyBee" />

            <div className="main-text">
              Resonate with <br />
              SenzyBee
            </div>
            <div className="text">
              <div className="subheadline">Footsies for your palm</div>
              <div className="headline">15 magic footsies send your touch</div>
              <div className="content">
                During your video call you hold SenzyBee in your palm as if you’re
                holding his hand. He’s with you and you can feel his touch while you
                look into his eyes deeply. 
                <br/>SenzyBee’s footsies make you feel you’re together and the distance between you vanishes.
                <br/><br/>It’s all in the footsies...
              </div>
              
                {/* <HashLink to="/#features">
                  <button>More</button>
                </HashLink> */}

              <HashLink to="/#features">
                <button>More</button>
              </HashLink>
              
            </div>
          </ResonateContainer>
        )
        }
      }
      </ImageContext.Consumer>
  );
};

const ResonateContainer = styled.div`
  position: relative;
  background: linear-gradient(100.78deg, #2d4978 7.67%, #021226 147.31%);
  height: 176rem;
  padding: 25rem 3rem 17.5rem;
  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 10rem 2rem 10rem;
    height: 100vh;
    min-height: 90rem;
  }

  .main-text {
    position: relative;
    font-size: 7.2rem;
    line-height: 110%;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
    color: #d8e0e3;
    z-index: 1;

    @media (max-width: 768px) {
      font-size: 3.6rem;
    }
  }

  .text {
    position: relative;
    margin-top: 30vh;
    color: #fff;
    float: right;
    width: 27vw;

    @media (max-width: 768px) {
      margin-top: 20vh;
      width: 70vw;
    }

    .subheadline {
      font-size: 1.8rem;
      line-height: 110%;
      font-weight: 300;
      text-transform: uppercase;
      color: #d8e0e3;

      @media (max-width: 768px) {
        font-size: 1.3rem;
      }
    }

    .headline {
      font-size: 4.8rem;
      line-height: 110%;
      font-weight: 300;
      margin: 1.3rem 0;

      @media (max-width: 768px) {
        font-size: 2rem;
        margin: 1rem 0;
      }
    }

    .content {
      font-size: 2.4rem;
      font-weight: 300;

      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
    }

    button {
      color: #fff;
      border: 2px solid #fff;
      font-size: 1.6rem;
      text-transform: uppercase;
      margin-top: 2.5rem;
      float: right;

      &:hover {
        background: #fff;
        color: #304972;
      }
    }
  }
`;

const LeftCircle = styled.img`
  position: absolute;
  top: 25.85vh;
  left: 0;

  @media (max-width: 768px) {
    width: 55.2vw;
    top: 36.25vh;
  }
`;

const RightCircle = styled.img`
  position: absolute;
  top: 8.75vh;
  right: 0;

  @media (max-width: 768px) {
    width: 40.53vw;
  }
`;

const Hands = styled.img`
  position: absolute;
  bottom: -110px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  @media (max-width: 768px) {
    height: 63.75vh;
    width: 100%;
    bottom: 0px;
  }
`;

export default Resonate;
