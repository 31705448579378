// Import dependencies
import styled from "styled-components";
import ImageContext from '../components/ImageContext';

const LongArrow = () => {
  return (
    <ImageContext.Consumer>
      {imgCtx => {
        // console.log("Re-render... firstReady: " + imgCtx.state.firstReady);
        return (
          <ArrowContainer>
            <img src={imgCtx.getFirst("to-video-long.svg")} alt="Pointer to long distance relationship with SenzyBee" className="long-arrow" />;
          </ArrowContainer>
        )}
      }
    </ImageContext.Consumer>
  );
};

const ArrowContainer = styled.div`
  position: relative;
  height: 0px;

  @media (max-width: 768px) {
    display: none;
  }

  .long-arrow {
    position: absolute;
    top: -3vh;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export default LongArrow;
