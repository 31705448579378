// Import dependencies
import styled, { keyframes } from "styled-components";
import ImageContext from '../../components/ImageContext';
import PurchaseButton from "../../components/PurchaseButton";

const Messenger = () => {
  
  return (
    <ImageContext.Consumer>
      {imgCtx => {
        return(
        <MessengerSection id="messengersection">
          <FullCircle src={imgCtx.getBlue("full-circle-border.svg")} alt="Bubbling SenzyBee joy" />
          <RightCircle src={imgCtx.getBlue("right-border-ellipse.svg")} alt="Bubbling SenzyBee hapiness" />
          <Man src={imgCtx.getBlue("phone_man.png")}  alt="Distance man happy with SenzyBee" />
          <Woman src={imgCtx.getBlue("phone_woman.png")} alt="Distance woman want to contact her lover with SenzyBee"/>
          {/* <StickyMessenger 
                  innerZ={6} 
                  srcLeft={imgCtx.getBlue("man-left.png")} 
                  srcRight={imgCtx.getBlue("woman-right.png")}        
          >
          </StickyMessenger> */}
          
          <Love>
            <span className="ripple"></span>
            <span className="ripple2"></span>
            <span className="ripple3"></span>
            <img src={imgCtx.getBlue("love.svg")} alt="Love for SenzyBee" />
          </Love>
          <div className="main-text">
            Now <br />
            messenger
            <br /> sends your
            <br /> touch
          </div>
          <div className="text">
            <img src={imgCtx.getBlue("diamond.svg")} alt="Rare diamond SenzyBee" className="diamond" />
            <div className="subheadline">Touch app</div>
            <div className="headline">
              Enjoy your messenger app with physical touch
            </div>
            <div className="content">
              Your fav messenger is beeping. You got a smiley. 
              But now, it is something different: you just got 
              a full screen bubbly smiley you can actually feel on your palm.
              <br/><br/>If a picture is worth a thousand words, how many words is your honey’s touch worth?
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
                  <PurchaseButton text="Order Now" style={{color: "white"}}/>
                </div>
            </div>
          
        </MessengerSection>
        )
      }
      }
    </ImageContext.Consumer>
  );
};

const MessengerSection = styled.div`
  background: linear-gradient(100.78deg, #2d4978 7.67%, #021226 147.31%);
  padding: 0rem 2rem 0rem 2rem;
  position: relative;
  height: 360vh;

  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 0rem 2rem 0rem;
    height: 180vh;
    min-height: 150rem;
  }

  .main-text {
    position: relative;
    margin-top: 20rem;
    font-size: 7.2rem;
    line-height: 110%;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
    color: #d8e0e3;
    // z-index: 1;

    @media (max-width: 768px) {
      font-size: 3.6rem;
      margin-top: 10rem;

    }
  }

  .text {
    position: relative;
    margin-top: 85vh;
    color: #fff;
    float: right;
    width: 28vw;
    // z-index: 4;

    @media (max-width: 768px) {
      margin-top: 28vh;
      width: 70vw;
      z-index: 0;
    }

    .diamond {
      margin-bottom: 2.5rem;

      @media (max-width: 768px) {
        margin-bottom: 1rem;
      }
    }

    .subheadline {
      font-size: 1.8rem;
      line-height: 110%;
      font-weight: 300;
      text-transform: uppercase;
      color: #d8e0e3;

      @media (max-width: 768px) {
        font-size: 1.3rem;
      }
    }

    .headline {
      font-size: 4.8rem;
      line-height: 110%;
      font-weight: 300;
      margin: 1.3rem 0;

      @media (max-width: 768px) {
        font-size: 2rem;
        margin: 1rem 0;
      }
    }

    .content {
      font-size: 2.4rem;
      font-weight: 300;

      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
    }
  }
`;

const FullCircle = styled.img`
  position: absolute;
  top: 5vh;
  right: 14vh;

  @media (max-width: 768px) {
    width: 44.53vw;
    top: 31px;
    right: 20px;
  }
`;

const RightCircle = styled.img`
  position: absolute;
  top: 231vh;
  right: 0;

  @media (max-width: 768px) {
    width: 100vw;
    top: 106vh;
    right: -100px;
  }
`;

const Ripple = keyframes`
    0% {
        transform: scale(1);
        filter: blur(0px);
        opacity: 1;
    }
    100% {
        transform: scale(3);
        filter: blur(8px);
        opacity: 0;
    }
`;

const Love = styled.div`
  position: absolute;
  left: 20vw;
  top: 218vh;

  @media (max-width: 768px) {
    width: 15.15vw;
    top: 83rem;

    img {
      width: 47px;
      height: 47px;
    }
  }

  span {
    background: transparent;
    border: 4px solid #fff;
    border-radius: 50%;
    position: absolute;
    opacity: 0;
    width: 278px;
    height: 278px;
    left: -120%;
    top: -120%;

    @media (max-width: 768px) {
      width: 126px;
      height: 126px;
      left: -80%;
      top: -80%;
    }
  }

  .ripple {
    animation: ${Ripple} 9s linear infinite;
  }

  .ripple2 {
    animation: ${Ripple} 9s linear infinite;
    animation-delay: 3s;
  }

  .ripple3 {
    animation: ${Ripple} 9s linear infinite;
    animation-delay: 6s;
  }
`;

const Man = styled.img`
  position: absolute;
  top: 256vh;
  left: 19.5vw;

  width: auto;
  height: 50vh;

  @media (max-width: 768px) {
    width: auto;
    height: 35.87vh;
    top: 96rem;
    left: 25%;
    z-index: 2;
  }
`;

const Woman = styled.img`
  position: absolute;
  top: 270vh;
  right: 19.5vw;
  width: auto;
  height: 50vh;
  @media (max-width: 768px) {
    width: auto;
    height: 35.87vh;
    top: 100.7rem;
    right: 25%;
    z-index: 2;
  }
`;

export default Messenger;
