// Import dependencies
import styled, { keyframes } from "styled-components";
import { useState } from "react";

// Components
import Sizes from "./ToggleBlocks/Sizes";
import FeatureContainer from "./ToggleBlocks/FeatureContainer";
import Modes from "./ToggleBlocks/Modes";
import ImageContext from '../components/ImageContext';

const ToggleBlocks = () => {
  const [block, setBlock] = useState("features");
  const addActive = (newblock) => {
    if (block === newblock) {
      return "active";
    } else {
      return " ";
    }
  };

  return (
    <ImageContext.Consumer>
      {imgCtx => {
        return (
            <FeaturesBlock id="features" backgr={imgCtx.getLast("features-background.png")}>
              <SwitchBox>
                <ul>
                  <li>
                    <span
                      className={`${addActive("modes")}`}
                      onClick={() => setBlock("modes")}
                    >
                      Modes
                    </span>
                  </li>
                  <li>
                    <span
                      className={`${addActive("features")}`}
                      onClick={() => setBlock("features")}
                    >
                      Features
                    </span>
                  </li>
                  <li>
                    <span
                      className={`${addActive("sizing")}`}
                      onClick={() => setBlock("sizing")}
                    >
                      Sizing
                    </span>
                  </li>
                </ul>
              </SwitchBox>
              <div className={block === "features" ? "visible" : "hidden"}>
                <FeatureContainer />
              </div>
              <div className={block === "sizing" ? "visible" : "hidden"}>
                <Sizes />
              </div>
              <div className={block === "modes" ? "visible" : "hidden"}>
                <Modes />
              </div>
            </FeaturesBlock>)
      }
      }
      </ImageContext.Consumer>
  );
};

const FadeToTop = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const FeaturesBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 8rem 7rem 5rem;
  background: url(${props => props.backgr}), #D8E0E3;
  background-size: cover;
  flex-flow: column wrap;
  min-height: 100rem;
  position: relative;
  overflow: hidden;
  z-index: 5;
  .visible {
    display: flex;
    animation: ${FadeToTop} 2s forwards;
    width: 100%;
  }

  .hidden {
    display: none;
  }

  .hide {
    display: none;
  }

  @media (max-width: 768px) {
    padding: 5rem 2.3rem 2.5rem;
    min-height: 0vh;
  }

  .bottom-text {
    color: #304972;
    font-size: 1.3rem;
    text-align: center;
    position: absolute;
    bottom: 5rem;
  }
`;

const SwitchBox = styled.div`
  // padding: 2.5rem 5rem;
  // margin-bottom: 2.5rem;
  

  @media (max-width: 768px) {
    padding: 0;
  }

  ul {
    list-style: none;
    
    display: flex;
    align-items: center;
    justify-content: space-around;

    border: 2px solid #304972;
    padding: 1.3rem 3rem;
    border-radius: 10px;
    width: 80vh;

    box-sizing: border-box;

    @media (max-width: 768px) {
      width: calc(100vw - 4.6rem);
    }

    li {
      font-size: 3.6rem;
      font-style: normal;
      font-weight: 300;

      @media (max-width: 768px) {
        font-size: 2rem;
      }

      span {
        text-decoration: none;
        color: #304972;
        position: relative;
        line-height: 110%;
        cursor: pointer;

        &:after {
          content: "";
          height: 0px;
          transition: all 0.2s ease;
          opacity: 0;
          display: block;
          position: absolute;
          bottom: -14px;
          background: #304972;
          width: 100%;
          content: "";
        }

        &:hover:after {
          height: 6px;
          opacity: 1;
          transition: all 0.2s ease-in-out;
        }
      }

      .active {
        position: relative;
        cursor: pointer;

        &:after {
          content: "";
          height: 6px;
          transition: all 0.2s ease;
          opacity: 1;
          display: block;
          position: absolute;
          bottom: -14px;
          background: #304972;
          width: 100%;
        }
      }
    }
  }
`;

export default ToggleBlocks;
