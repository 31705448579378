// Import dependencies
import styled, { keyframes } from "styled-components";
import { useState } from "react";
import ImageContext from '../../components/ImageContext';

const Modes = () => {
  const [mobilmode, setmobilMode] = useState(0);
  const [showModal, setshowModal] = useState(false);

  const mobilModeHandler = (direction) => {
    if (direction === "prev") {
      if (mobilmode === 0) {
        setmobilMode(1);
      } else {
        setmobilMode(mobilmode - 1);
      }
    }
    if (direction === "next") {
      if (mobilmode === 1) {
        setmobilMode(0);
      } else {
        setmobilMode(mobilmode + 1);
      }
    }
  };

  const switcherHandler = (number) => {
    setmobilMode(number);
  };

  return (
    <ImageContext.Consumer>
      {imgCtx => {
        // console.log("Re-render... lastReady: " + imgCtx.state.lastReady);
        return (
          <ModeContainer>
            <div className="desktop-section">
              <ModeSingle>
                <div
                  className={`modal-box ${
                    showModal === "messenger" ? "active" : "hidden"
                  }`}
                >
                  <div className="modal-inner">
                    <img src={imgCtx.getLast("modal-close.svg")} alt="Closing SenzyBee feature details" onClick={() => setshowModal("")} />
                    <div className="altnumber">1</div>
                    <div className="wrapper">
                      <div className="title">
                        Messenger <br /> mode
                      </div>
                      <div className="content">
                        As my hubby was away in New York, I decided to give him a
                        thoughtful thrill. So I drew what I felt, a little heart
                        shaped caress - my little work of love came through instantly
                        to his SenzyBee as we both got a tingle and felt it
                        simultaneously together. We loved it! You can make a direct
                        connection with your loved-one. By that we mean what you draw
                        is what she feels. Your caress comes through instantly and you
                        both enjoy it as one. We call it WYDIWYS,
                        what-you-draw-is-what-you-send. It’s something new you can do
                        together.
                      </div>
                    </div>
                  </div>
                </div>
                <img src={imgCtx.getLast("messenger-mode.svg")} alt="This is how the extraordinary messenger mode of SenzyBee app works" />
                <div className="number">1</div>
                <div className="wrapper">
                  <div className="title">Messenger mode</div>
                  <div className="content">
                    “Caress Draw” is pretty cool. You simply draw something (perhaps a
                    smiley) on SenzyBee’s touchpad and send your cute message...
                    <div
                      className="read-more"
                      onClick={() => setshowModal("messenger")}
                    >
                      Read More
                    </div>
                  </div>
                </div>
              </ModeSingle>
              <div className="horizonter"></div>
              <ModeSingle>
                <div
                  className={`modal-box ${
                    showModal === "realtime" ? "active" : "hidden"
                  }`}
                >
                  <div className="modal-inner">
                    <img src={imgCtx.getLast("modal-close.svg")} alt="Close single mode of SenzyBee" onClick={() => setshowModal("")} />
                    <div className="altnumber">2</div>
                    <div className="wrapper">
                      <div className="title">
                        Real Time <br /> mode
                      </div>
                      <div className="content">
                        As my hubby was away in New York, I decided to give him a
                        thoughtful thrill. So I drew what I felt, a little heart
                        shaped caress...
                      </div>
                    </div>
                  </div>
                </div>
                <img src={imgCtx.getLast("realtime-mode.svg")} alt="Senzybee's Real-time mode for couple in long distance relationship." />
                <div className="number">2</div>
                <div className="wrapper">
                  <div className="title">Real Time mode</div>
                  <div className="content">
                    As my hubby was away in New York, I decided to give him a
                    thoughtful thrill. So I drew what I felt, a little heart shaped
                    caress...
                    <div
                      className="read-more"
                      onClick={() => setshowModal("realtime")}
                    >
                      Read More
                    </div>
                  </div>
                </div>
              </ModeSingle>
            </div>

            <div className="mobil-section">
              <div className="switcher-box">
                <span
                  className={`modeSwitcher ${mobilmode === 0 ? "active" : ""}`}
                  onClick={() => switcherHandler(0)}
                ></span>
                <span
                  className={`modeSwitcher ${mobilmode === 1 ? "active" : ""}`}
                  onClick={() => switcherHandler(1)}
                ></span>
              </div>

              <img
                src={imgCtx.getLast("feature-prev.svg")}
                alt="Previous cool SenzyBee feature"
                className="ticker prev"
                onClick={() => mobilModeHandler("prev")}
              />

              <div className={mobilmode === 0 ? "visible" : "hidden"}>
                <ModeSingle>
                  <div
                    className={`modal-box ${
                      showModal === "messenger" ? "active" : "hidden"
                    }`}
                  >
                    <div className="modal-inner">
                      <img src={imgCtx.getLast("modal-close.svg")} alt="Close this fantastic SenzyBee feature" onClick={() => setshowModal("")} />
                      <div className="altnumber">1</div>
                      <div className="wrapper">
                        <div className="title">
                          Messenger <br /> mode
                        </div>
                        <div className="content">
                          As my hubby was away in New York, I decided to give him a
                          thoughtful thrill. So I drew what I felt, a little heart
                          shaped caress...
                        </div>
                      </div>
                    </div>
                  </div>
                  <img src={imgCtx.getLast("messenger-mode.svg")} alt="Closing Real-time mode description of Senzybee" />
                  <div className="number">1</div>
                  <div className="wrapper">
                    <div className="title">Messenger mode</div>
                    <div className="content">
                      “Caress Draw” is pretty cool. You simply draw something (perhaps
                      a smiley) on SenzyBee’s touchpad and send your cute message...
                      <div
                        className="read-more"
                        onClick={() => setshowModal("messenger")}
                      >
                        Read More
                      </div>
                    </div>
                  </div>
                </ModeSingle>
              </div>

              <div className={mobilmode === 1 ? "visible" : "hidden"}>
                <ModeSingle>
                  <div
                    className={`modal-box ${
                      showModal === "realtime" ? "active" : "hidden"
                    }`}
                  >
                    <div className="modal-inner">
                      <img src={imgCtx.getLast("modal-close.svg")} alt="Close real-time mode description of SenzyBee" onClick={() => setshowModal("")} />
                      <div className="altnumber">2</div>
                      <div className="wrapper">
                        <div className="title">
                          Real Time <br /> mode
                        </div>
                        <div className="content">
                          As my hubby was away in New York, I decided to give him a
                          thoughtful thrill. So I drew what I felt, a little heart
                          shaped caress...
                        </div>
                      </div>
                    </div>
                  </div>
                  <img src={imgCtx.getLast("realtime-mode.svg")} alt="Description of Real-time mode of Senzybee" />
                  <div className="number">2</div>
                  <div className="wrapper">
                    <div className="title">Real Time mode</div>
                    <div className="content">
                      As my hubby was away in New York, I decided to give him a
                      thoughtful thrill. So I drew what I felt, a little heart shaped
                      caress...
                      <div
                        className="read-more"
                        onClick={() => setshowModal("realtime")}
                      >
                        Read More
                      </div>
                    </div>
                  </div>
                </ModeSingle>
              </div>

              <img
                src={imgCtx.getLast("feature-next.svg")}
                alt="Next cool Senzybee feature"
                className="ticker next"
                onClick={() => mobilModeHandler("next")}
              />
            </div>
          </ModeContainer>)
      }}
    </ImageContext.Consumer>
  );
};

const FadeToTop = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Pulse = keyframes`
 0% {
   opacity: 0.8;
   transform: scale(1);
 }
 100% {
   opacity: 1;
   transform: scale(1.1);
 }
`;

const ModeContainer = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  position: relative;
  width: 100%;
  justify-self: center;
  align-self: center;
  margin-top: 5vh;

  .desktop-section {
    
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .mobil-section {
    display: none;

    @media (max-width: 768px) {
      display: flex;
      width: 100%;
    }

    @media (max-width: 330px) {
      display: flex;
      width: 70%;
    }
  }

  .switcher-box {
    position: absolute;
    left: 50%;
    top: 0px;
    z-index: 2;
    display: flex;
    min-width: 7rem;
    justify-content: space-between;
    transform: translateX(-50%);

    .modeSwitcher {
      height: 18px;
      width: 18px;
      background: #304972;
      border-radius: 50%;
      cursor: pointer;
      display: block;
    }

    .active {
      position: relative;

      &:after {
        content: "";
        display: block;
        position: absolute;
        height: 28px;
        width: 28px;
        border: 2px solid #304972;
        left: -40%;
        top: -40%;
        border-radius: 50%;
        animation: ${FadeToTop} 1s forwards;

        @media (max-width: 768px) {
          left: -35%;
          top: -35%;
        }
      }
    }
  }

  .visible {
    display: flex;
    animation: ${FadeToTop} 2s forwards;
    justify-content: center;
  }

  .hidden {
    display: none;
  }

  .horizonter {
    height: 25rem;
    width: 2px;
    background: #fff;
    margin: 7rem 3.8rem 0;
    align-self: flex-start;
  }

  .ticker {
    align-self: flex-end;
    margin-bottom: 10rem;
    transition: all 0.3s ease;
    cursor: pointer;

    @media (max-width: 768px) {
      align-self: center;
    }

    &:hover {
      animation: ${Pulse} 1s infinite alternate;
    }
  }
`;

const ModeSingle = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 768px) {
    width: auto;
    margin-top: 2.7rem;
  }

  .modal-box {
    position: absolute;
    background: #fff;
    display: flex;
    flex-flow: column wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 2.2rem 4.7rem 3.5rem;
    max-width: 44rem;
    box-sizing: border-box;

    .modal-inner {
      position: relative;

      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
        position: absolute;
        right: 0;
      }
    }

    .altnumber {
      color: #d4dde0;
      font-size: 9.6rem;
    }
  }

  .active {
    display: block;
    animation: ${FadeToTop} 2s forwards;
  }

  .hidden {
    display: none;
  }

  img {
    width: 53rem;
    height: 35rem;
    object-fit: contain;
    margin-bottom: 1.4rem;

    @media (max-width: 768px) {
      width: 25rem;
      height: 17rem;
      margin-bottom: 0;
    }
  }

  .number {
    color: #fff;
    font-size: 9.6rem;
    font-weight: 300;
    line-height: 110%;
  }

  .wrapper {
    max-width: 28vw;
    display: flex;
    align-items: center;
    flex-flow: column wrap;

    @media (max-width: 768px) {
      max-width: 100%;
    }

    .title {
      font-size: 3.6rem;
      font-weight: 300;
      line-height: 110%;
      color: #304972;
      margin-bottom: 1.5rem;
      text-align: center;
    }

    .content {
      font-size: 1.6rem;
      color: #304972;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
    }

    .read-more {
      font-size: 1.6rem;
      margin-top: 1.5rem;
      text-decoration: underline;
      color: #304972;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        opacity: 0.85;
      }
    }
  }
`;

export default Modes;
