// Import dependencies
import styled from "styled-components";
import { useState } from "react";
import ImageContext from '../components/ImageContext';

// Components
import MauticForm from "./MauticForm";
// import PurchaseButton from "../components/PurchaseButton";

const OurStory = () => {
  const [moreContent, setMoreContent] = useState(false);
  const [isTeamVisible, setIsTeamVisible] = useState(false);

  const showMoreContent = () => {
    setMoreContent(!moreContent);
  };
  
  const toggleTeamVisibility = () => {
    setIsTeamVisible(!isTeamVisible); // New function to toggle team visibility
  };

  return (
    <ImageContext.Consumer>
      {imgCtx => {
        return (
            <StorySection>
              <Line>
                <img src={imgCtx.getLast("star.svg")} alt={imgCtx.getLast("star.svg")} />
                <div className="subtitle">Our Story</div>
              </Line>

              <CarouselBox>
                <img src={imgCtx.getLast("carousel1.png")} alt="Happy couple hand in hand with SenzyBee" />
                <img src={imgCtx.getLast("carousel2.png")} alt="Loving couple looking each-others face with SenzyBee" />
                <img src={imgCtx.getLast("carousel3.png")} alt="Couple together walking thinking of SenzyBee" />
                <img src={imgCtx.getLast("carousel4.png")} alt="SenzyBee for maintaining long distance relationship" />
                <img src={imgCtx.getLast("carousel5.png")} alt="Beautiful lover thinking of her partner. SenzyBee can help her." />
              </CarouselBox>
            
              <SenzyStory>
                <div className="title">
                  “Mirror Touch” trick I learned from a mouse...
                </div>
                <div className="content">
                  Tears ran down her eyes, “damnit Laci, I want to feel you, your
                  touch!” My heart fell 20 floors, I sheepishly answered “I do too” – I
                  really didn't know what to say.
                </div>
                <div className={`content ${moreContent === true ? "" : "hide"}`}>
                  I couldn't get that out of my mind. <br/><br/>Tunde is my girlfriend, and we
                  were apart weekdays because I was up to my eyeballs finishing a
                  project for a dating app and it was most effective for our team to
                  work together physically. I kept thinking about our being apart, if
                  only we could have some physical touch, it would make it a little
                  easier. <br/><br/>Then it happened. I looked down at my hand while scrolling a
                  mouse – then up to my screen watching the icon move directly as I
                  scrolled! What if I could touch-scroll, literally “Mirror Touch,” and
                  Tunde could feel my touch? Could I touch my sweetheart Tunde remotely?
                  Could I bring back tender moments being together when I slipped her a
                  gentle touch to make her smile? <br/><br/>YES! I formalized the concept in my
                  head and called my friend Imre, he about fell out of his chair,
                  PERFECT! he said. You see, he flies back and forth between Hungary and
                  California, he too knows first hand the strains of being apart without
                  the warm touch of loved ones. We brought in Miguel, Balazs and Attila,
                  soon SenzyBee took on a life of its own. <br/><br/>Now it's filed with the US
                  Patent and Trademark Office to protect the invention of this NEW
                  remote touch device… <br/><br/>The ONLY touch device in the world that
                  physically communicates your touch and helps bring long distance
                  relationships a little closer. Even if you are not apart from your
                  loved one, 58% of people are or will be in a long distance
                  relationship someday*.<br/><br/>
                  So
                  SenzyBee is worth checking out just in case – besides, it's a lotta
                  fun!
                </div>
                <div
                  className={`text-link ${moreContent === true ? "hide" : ""}`}
                  onClick={() => {
                    showMoreContent();
                  }}
                >
                  Read more
                </div>
              </SenzyStory>

              <div className="form">
                <MauticForm />
              </div>

              <Line onClick={toggleTeamVisibility}>
                <img src={imgCtx.getLast("team.svg")} alt="The team that created SenzyBee" />
                <div className="subtitle">Our Team</div>
              </Line>

              <div onClick={toggleTeamVisibility} className="toggle-team">
                {isTeamVisible ? 'Hide Team' : 'Show Team'} {/* Toggle button text */}
              </div>
                  
              {isTeamVisible && (<div className="teammember-container">
                <TeamMember>
                  <img src={imgCtx.getLast("team1.png")} alt="The mastermind behind SenzyBee" />
                  <div className="name">Laci</div>
                  <div className="role">founder, software developer rock-star </div>
                </TeamMember>

                <TeamMember>
                  <img src={imgCtx.getLast("team2.png")} alt="The marketing guru of SenzyBee" />
                  <div className="name">Imre</div>
                  <div className="role">co-founder, Savvy Marketer</div>
                </TeamMember>

                <TeamMember>
                  <img src={imgCtx.getLast("team3.png")} alt="The guy who dreamt the look and feel of SenzyBee landing page" />
                  <div className="name">Attila</div>
                  <div className="role">UI/UX ninja</div>
                </TeamMember>

                <TeamMember>
                  <img src={imgCtx.getLast("team4.png")} alt="The guru who gave his best to create SenzyBee electronics" />
                  <div className="name">Balazs</div>
                  <div className="role">electronics guru</div>
                </TeamMember>

                <TeamMember>
                  <img src={imgCtx.getLast("team5.png")} alt="She knows what our soul needs" />
                  <div className="name">Barbara</div>
                  <div className="role">Psychologist extraordinaire </div>
                </TeamMember>

                <TeamMember>
                  <img src={imgCtx.getLast("team6.png")} alt="The fellow who sculptured SenzyBee" />
                  <div className="name">Miguel</div>
                  <div className="role">3D designer juggernaut</div>
                </TeamMember>

                <TeamMember>
                  <img src={imgCtx.getLast("team7.png")} alt="The one who programmed SenzyBee's website." />
                  <div className="name">Daniel</div>
                  <div className="role">Frontend Cyberpunk</div>
                </TeamMember>
              </div>)}
              {/* <div style={{display: "flex", justifyContent: "flex-end"}}>
                  <PurchaseButton text="Order Now"/>
              </div> */}
            </StorySection>)
      }}
    </ImageContext.Consumer>
  );
};

const StorySection = styled.div`
  padding: 10rem 9rem 15rem;
  box-sizing: border-box;
  overflow: hidden;
  background: #fff;

  .title {
    font-size: 4.8rem;
    color: #304972;
    font-weight: 300;
    line-height: 110%;
    margin-bottom: 5.3rem;
  }

  @media (max-width: 768px) {
    padding: 7.5rem 2rem 5rem;
  }

  .teammember-container {
    display: flex;
    margin: 10rem 3rem 0rem 7.2rem;
    flex-flow: row wrap;
    box-sizing: border-box;

    @media (max-width: 768px) {
      margin: 7.5rem 1rem 0rem;
      justify-content: space-between;
      gap: 0;
    }
  }

  .form {
    margin: 3.5rem 0 10rem 3.6rem;
    display: flex;

    @media (max-width: 768px) {
      width: initial;
      margin: 0 0 7.5rem 0;
    }

    input {
      background: none;
      color: #304972;
      font-size: 1.8rem;
      border: none;
      border-bottom: 2px solid #304972;
      line-height: 150%;
      width: 100%;
      font-style: italic;
    }

    input::placeholder {
      opacity: 0.5;
      color: #304972;
    }
  }
`;

const CarouselBox = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 10rem;
  margin-top: 2rem;

  @media (max-width: 768px) {
    transform: translateX(-25%);

    img {
      margin-left: 3rem;
    }
  }
  // @media (max-width: 768px) {
  //   @keyframes reveal {
  //     to { 
  //         opacity: 1; 
  //     }
  //   }
  //   shuffle {
  //       min-height: 100vh;
  //       position: relative;
  //   }
  //   shuffle img { 
  //       width: 33%; 
  //       opacity: 0; 
  //       transform: scale(1.3);
  //   }
  //   reveal img { 
  //       animation: reveal 1s 1s forwards; 
  //   }
  // }
`;

const SenzyStory = styled.div`
  padding: 5rem 0rem 0rem 3.6rem;
  color: #304972;
  width: 100%;

  @media (max-width: 768px) {
    width: initial;
    padding: 0 1rem 2rem 1rem;
  }

  .title {
    font-size: 4.8rem;
    font-weight: 300;
    line-height: 110%;
    margin-bottom: 1.3rem;

    @media (max-width: 768px) {
      font-size: 3.6rem;
    }
  }

  .content {
    font-size: 2.4rem;
    font-weight: 300;

    @media (max-width: 768px) {
      font-size: 1.8rem;
    }
  }

  .hide {
    display: none;
  }

  .text-link {
    color: #304972;
    margin: 1.3rem 0rem 4.8rem;
    cursor: pointer;
    font-size: 2rem;
    width: 100%;
    font-weight: 300;

    @media (max-width: 768px) {
      margin: 1.3rem 0rem 2.6rem;
    }
  }
`;

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 2px solid #d8e0e3;
  padding-bottom: 2rem;

  @media (max-width: 768px) {
    border-bottom: 1px solid #d8e0e3;
  }

  img {
    @media (max-width: 768px) {
      width: 24px;
      height: 24px;
    }
  }

  .subtitle {
    color: #304972;
    font-size: 3.6rem;
    line-height: 110%;
    font-weight: 300;

    @media (max-width: 768px) {
      font-size: 2.4rem;
    }
  }
`;

const TeamMember = styled.div`
  display: flex;
  flex-flow: column wrap;
  flex-basis: 16%;
  text-transform: uppercase;
  color: #252525;
  box-sizing: border-box;
  margin: 0 3vw 5rem;

  @media (max-width: 768px) {
    flex-basis: 45%;
    margin: 0 0vw 5rem;
  }

  img {
    width: 100px;
    height: 100px;
  }

  .name {
    font-size: 1.8rem;
    font-weight: 600;

    margin: 2.5rem 0 0.5rem;
  }

  .role {
    font-size: 1.5rem;
    line-height: 120%;
  }
`;

export default OurStory;
