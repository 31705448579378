// Import dependencies
import styled from "styled-components";
import { useEffect } from "react";

//Components
import FirstContentSection from "./FlyingSenzy/FirstContent";
import PersonalTouch from "./FlyingSenzy/PersonalTouch";
import SecondContentSection from "./FlyingSenzy/SecondContent";
import ThirdContentSection from "./FlyingSenzy/ThirdContent";
import Resonate from "./FlyingSenzy/Resonate";
import Messenger from "./FlyingSenzy/Messenger";

import StickyBee from '../components/StickyBee';


const FlyingSenzy = () => {
  
  useEffect(() => {
    window.addEventListener("load", () => {
      console.log("FlyingSenzy loaded...")
    });
  }, []);
  
  /*
  The method of the 3D animation is really simple: 
  we calculate the offset of the CSS ID marked elements from the top,
  and we divide the difference of the start end the endpoint by the number of the pictures we have.
  This gives us the ratio of "how many pixels we should scroll to change an image".
  After that, we reduce the actual scroll amount in our container with the distance of the container's top, then
  we divide it by our ratio. This will be the number of the image what we should use.
  */

  return (
    <FlyingBox id="flying">
      <StickyBee 
        top={20} 
        bottomBoundary="#messengersection" 
        innerZ={3}
      >

      </StickyBee>
      <FirstContentSection />    

      <PersonalTouch />
      <SecondContentSection id="secondContent"/>
      <div id="flyingsec"></div>
      <Resonate />

      <ThirdContentSection />
      <div id="flyingthird"></div>
      <Messenger />
      
    </FlyingBox>
  );
};

const FlyingBox = styled.div`
  position: relative;
  background: #fff;
  .wrapper {
    position: relative;
  }

`;


export default FlyingSenzy;