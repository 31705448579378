// const ColorSelector = ({ selectedColor, onColorChange }) => {
//     const colors = ['white', 'blue']; // Add more colors if needed
  
//     return (
//       <div>
//         {colors.map(color => (
//           <span
//             key={color}
//             onClick={() => onColorChange(color)}
//             style={{
//               display: 'inline-block',
//               backgroundColor: color,
//               borderRadius: '50%',
//               width: '25px',
//               height: '25px',
//               margin: '5px',
//               border: selectedColor === color ? '2px solid black' : '1px solid grey',
//               cursor: 'pointer'
//             }}
//           />
//         ))}
//       </div>
//     );
//   };
  
// const ColorSelector = ({ selectedColor, onColorChange }) => {
//     const colors = ['white', 'blue']; // Add more colors if needed
//     const circleSize = 25; // Base size for unselected color circles
  
//     return (
//       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
//         {colors.map(color => {
//           const isSelected = selectedColor === color;
//           const size = isSelected ? circleSize * 1.5 : circleSize; // 1.5 times bigger if selected
  
//           return (
//             <label key={color} style={{ margin: '10px 0', display: 'flex', alignItems: 'center' }}>
//               <input 
//                 type="radio" 
//                 name="color" 
//                 value={color} 
//                 checked={isSelected}
//                 onChange={() => onColorChange(color)}
//                 style={{ display: 'none' }} // Hide the default radio button
//               />
//               <span 
//                 onClick={() => onColorChange(color)}
//                 style={{
//                   display: 'inline-block',
//                   backgroundColor: color === 'white' ? '#FFF' : color, // Specific color code for white
//                   borderRadius: '50%',
//                   width: `${size}px`,
//                   height: `${size}px`,
//                   border: isSelected ? '2px solid black' : '1px solid grey',
//                   cursor: 'pointer',
//                   marginRight: '5px'
//                 }}
//               />
//               <span style={{
//                 border: isSelected ? '1px solid black' : 'none',
//                 padding: '2px 4px',
//                 borderRadius: '4px'
//               }}>
//                 {color.charAt(0).toUpperCase() + color.slice(1)} {/* Capitalize the color name */}
//               </span>
//             </label>
//           );
//         })}
//       </div>
//     );
//   };
  

const ColorSelector = ({ selectedColor, onColorChange }) => {
    const colors = ['white', 'blue']; // Add more colors if needed
  
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {colors.map(color => {
          const isSelected = selectedColor === color;
          return (
            <label key={color} style={{ margin: '0 10px', display: 'flex', alignItems: 'center' }}>
              <input 
                type="radio" 
                name="color" 
                value={color} 
                checked={isSelected}
                onChange={() => onColorChange(color)}
                style={{ display: 'none' }} // Hide the default radio button
              />
              <span 
                onClick={() => onColorChange(color)}
                style={{
                  display: 'inline-block',
                  backgroundColor: color === 'white' ? '#FFF' : color, // Specific color code for white
                  borderRadius: '200px',
                  width: '40px',
                  height: '40px',
                  border: isSelected ? '4px solid black' : '0px solid grey',
                  cursor: 'pointer',
                  marginRight: '5px'
                }}
              />
            </label>
          );
        })}
      </div>
    );
  };
  
  
  
  
  export default ColorSelector;