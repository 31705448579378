
const isTest = false;
const isLocal = false;
const pixelId = '2522316261273437';
const serviceUrl = isLocal ? 'http://localhost:8787/fetch' : 'https://senzybee.com/fbevent';

const createEventId = (eventName) => {
    const randomNumber = Math.floor(1000000000 + Math.random() * 9000000000);
    return `${eventName}.${randomNumber}`;
}

const trackEvent = (eventName, customParams, eventId) => {
    if (window.fbq) {
        if (eventId) {
            window.fbq('track', eventName, customParams, {
                    eventID: eventId,
                });
        } else {
            window.fbq('track', eventName, customParams);
        }
    }
};

const saveFbp = () => {
    const fbp = createFbp();
    localStorage.setItem('fbp', fbp);
}

const loadFbp = () => {
    return localStorage.getItem('fbp');
}

const saveFbclidToFbc = (fbclid) => {
    const fbc = createFbc(fbclid);
    if (fbc) {
        localStorage.setItem('fbc', fbc);
    }
}
  
const loadFbcFromFbclid = () => {
    // Retrieve the fbc value based on fbclid
    return localStorage.getItem('fbc');
}

const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
}

const createFbc = (fbclid) => {
    if (!fbclid) {
        return null;
    }
    const now = Date.now();
    const fbc = `fb.1.${now}.${fbclid}`;
    return fbc;
}

const createFbp = () => {
    const now = Date.now();
    const randomPart = Math.floor(Math.random() * 1000000000);
    const fbp = `fb.1.${now}.${randomPart}`;
    return fbp;
}

const createUserData = (location) => {
    const fbc = getFbc(location);
    const fbp = getFbp();
    
    const userData = {
        fbc,
        fbp,
        client_user_agent: navigator.userAgent,
    };
    return userData;
}

const setFbc = (location) => {
    const searchParams = new URLSearchParams(location.search);
    const fbclid = searchParams.get('fbclid');
    const fbcCookie = getCookie('_fbc');

    if (!fbcCookie) {
        const fbc = loadFbcFromFbclid();
        if (!fbc || fbc.indexOf(fbclid) === -1) {
            saveFbclidToFbc(fbclid);
        }
    }
}

const getFbc = () => {
    const fbc = getCookie('_fbc');
    if (!fbc) {
        const fbc = loadFbcFromFbclid();
        return fbc;
    }
    return fbc;
}

const setFbp = () => {
    const fbpCookie = getCookie('_fbp');
    if (!fbpCookie) {
        const fbp = loadFbp();
        if (!fbp) {
            saveFbp();
        }
    }
}

const getFbp = () => {
    const fbp = getCookie('_fbp');
    if (!fbp) {
        return loadFbp();
    }
    return fbp;
}

const sendFbEvent = async (eventName, location, customData, eventId) => {
    const userData = createUserData(location);
    const eventTime = Math.floor(Date.now() / 1000);
    const eventSourceUrl = `${window.location.origin}${location.pathname}`;
    console.log('eventSourceUrl: ', eventSourceUrl);
    
    var data = 
        {
            'eventName': eventName,
            'eventTime': eventTime,
            'actionSource': 'website',
            'userData': userData,
            'customData': customData,
            'eventSourceUrl': eventSourceUrl,
            'eventId': eventId
        }

    const body = {
		data: data,
		pixelId: pixelId,
        isTest: isTest,
    };

    const init = {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
            'content-type': 'application/json;charset=UTF-8',
        },
    };

    try {
        console.log('Sending event, url: ', serviceUrl);
        console.log('Sending event: ', JSON.stringify(body));
        const response = await fetch(serviceUrl, init);
        return response;

    } catch(error) {
        console.error('Error sending event: ', error.message);
        console.error('Stack Trace: ', error.stack);
    }
}

const cleanUrl = (searchParamsString) => {
    const params = new URLSearchParams(searchParamsString);
    const filteredParams = new URLSearchParams();
    for (const [key, value] of params) {
      if (key.startsWith('quantity')) break;
      if (key.startsWith('value')) break;
      if (key.startsWith('currency')) break;
      
      filteredParams.append(key, value);
    }
  
    // Return the filtered search parameters as a string
    return filteredParams.toString();
}

export {
    setFbc,
    getFbc,
    setFbp,
    getFbp,
    sendFbEvent,
    trackEvent,
    createEventId,
    cleanUrl,
    
};