// Import dependencies
import styled from "styled-components";
import { useState } from "react";
import { HashLink } from "react-router-hash-link";

import ImageContext from '../components/ImageContext';

// Assets
import Header from "../components/Header";
import ellipse from "../assets/png/ellipse.png";

const Hero = () => {
  const [MenuVis, setMenuVis] = useState(false);

  return (
    <ImageContext.Consumer>
      {imgCtx => {
        // console.log("Re-render... firstReady: " + imgCtx.state.firstReady);
        return (
        <HeroSection>
          <Header MenuVis={MenuVis} setMenuVis={setMenuVis} />
          <div className="line"></div>
          <HeroContainer>
            <div className="text">
              <div className="hero-line">
                If you're like me, when she's away you miss her touch. <br/>Now you <b>can </b> 
                feel her touch…
              </div>
              <div className="sub-hero">
                “As my girlfriend lovingly strokes, I feel her caress, even though
                we’re 3,400 miles apart!”
              </div>
              <div className="button-wrapper">
                  <HashLink to="/#video">
                    <button className="kickstarter">Check video</button>
                  </HashLink>
                  <HashLink to="/#features">
                    <button className="text-link">Read more </button>
                  </HashLink>
              </div>
            </div>
            <div className="senzys">
              <img src={imgCtx.getFirst("senzys.png")} alt="SenzyBee couple in long distance relationship" />
            </div>
          </HeroContainer>
          <div className="to-video">
            Wonder how? This is truly new, <br />
            check out this one-minute video…
            <img src={imgCtx.getFirst("to-video-short.svg")} alt="Short arrow to couples separated remotely" className="short-arrow" />
          </div>
        </HeroSection>
        )
      }}
    </ImageContext.Consumer>
  );
}

const HeroSection = styled.div`
  background: url(${ellipse}) no-repeat, #e5ded4;
  background-position: 65vw 20vh;
  padding: 0 8.9rem;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  overflow-x: hidden;

  @media (max-width: 768px) {
    padding: 0rem 2rem 0rem;
    min-height: initial;
    background-position: 25vw 25vh;
  }

  .line {
    height: 0.1rem;
    width: 100%;
    background: #c5b9b2;
  }

  .ellipse {
    position: absolute;
    bottom: -38vh;
    right: 0;
    z-index: 0;

    @media (max-width: 768px) {
      max-width: 75%;
      height: auto;
      bottom: -5vh;
    }
  }
  .to-video {
    color: #304972;
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 4.4rem;
    margin-top: 2.2rem;
    display: flex;
    flex-flow: column wrap;
    z-index: 1;

    @media (max-width: 768px) {
      margin-bottom: 2.5rem;
      font-size: 1.5rem;
    }

    .short-arrow {
      display: none;
      @media (max-width: 768px) {
        display: block;
        margin-top: 1.1rem;
      }
    }
  }
`;

const HeroContainer = styled.div`
  margin-top: 5.6rem;
  display: flex;
  flex-flow: row wrap;

  @media (max-width: 768px) {
    margin-top: 2.5rem;
  }

  .text {
    width: 57%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .hero-line {
    font-size: 5.5rem;
    line-height: 110%;
    color: #304972;
    font-weight: 300;
    margin: 4.2rem 0rem 3rem;

    @media (max-width: 768px) {
      font-size: 3rem;
      margin: 0rem 5rem 1rem 0rem;
    }
  }

  .sub-hero {
    font-size: 2.5rem;
    font-weight: 300;
    color: #304972;
    margin-bottom: 5.3rem;

    @media (max-width: 768px) {
      font-size: 1.8rem;
      margin-bottom: 2.5rem;
      margin-right: 5rem;
    }
  }

  .button-wrapper {
    display: flex;
    align-items: center;

    .text-link {
      color: #304972;
      width: auto;
      margin-left: 2.5rem;
    }
  }

  .senzys {
    width: 43%;
    display: flex;

    @media (max-width: 768px) {
      width: 100%;
      justify-content: flex-end;
    }

    img {
      z-index: 1;
      max-width: 50vw;
      object-fit: contain;

      @media (max-width: 768px) {
        margin-top: 4.1rem;
        max-width: 82vw;
        height: initial;
        max-height: 200px;
        margin-bottom: -5rem;
      }
    }
  }
`;

export default Hero;
