import styled from "styled-components";
//Global Styles
import GlobalStyle from "./GlobalStyle";
// Components
import Hero from "./sections/HeroSection";
import CookieBar from "./components/CookieBar";
import FirstVideoSection from "./sections/FirstVideoSection";
import LongArrow from "./components/LongArrow";
import FlyingSenzy from "./sections/FlyingSenzy";
import ExplainerVideoSection from "./sections/ExplainerVideoSection";
import ToggleBlocks from "./sections/ToggleBlocks";
import Footer from "./sections/Footer";
import OurStory from "./sections/OurStory";
import ImageProvider from './components/ImageProvider';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PurchasePage from './pages/purchase';
import ThankYouPage from './pages/thankyou';
import { setFbc, setFbp, sendFbEvent, trackEvent, createEventId } from './tools/FbTools';
import { useLocation } from 'react-router-dom';

//Import dependencies
import { useEffect } from "react";
//Then redefine the old console
// var console
console.log("process.env.NODE_ENV: ", process.env.NODE_ENV)

const MainPage = () => {
  let location = useLocation();
  useEffect(() => {
    
    setFbp();
    setFbc(location);

    // sendFbEvent('ViewContent', location, {page: '/'});
    const eventName = 'ViewContent';
    const eventId = createEventId(eventName);
    const customParams = {page: '/'};
    trackEvent(eventName, customParams, eventId);
    sendFbEvent(eventName, location, customParams, eventId);
  });

  return (
    <>
      <CookieBar />
      <Hero />
      <LongArrow />
      <FirstVideoSection />
      <FlyingSenzy />
      <ExplainerVideoSection />
      <ToggleBlocks />
      <OurStory />
      {/* <SignUp /> Optionally include this */}
      <Footer />
    </>
  );
};

const App = () => {
  // const [isLoading, setIsLoading] = useState(true);

  
  useEffect(() => {
    window.addEventListener("load", () => {
      document.body.style.height = "100%";
      document.body.style.overflow = "initial";
      console.log("process.env.NODE_ENV: ", process.env.NODE_ENV)

    });

    setTimeout(() => {
      // setIsLoading(false);
      document.body.style.height = "100%";
      document.body.style.overflow = "initial";
    }, 5000);
    
  }, []);

  return (    
    <ImageProvider>
      <AppFrame id="home">
        <GlobalStyle />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/purchase" element={<PurchasePage />} />
            <Route path="/thankyou" element={<ThankYouPage />} />
          </Routes>
        </BrowserRouter>
      </AppFrame>
    </ImageProvider>
  );
};

const AppFrame = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  @media (min-aspect-ratio: 1/1) {
    max-width: 120vh;
  }
`;

export default App;
