// Import dependencies
import { useState, useRef, useEffect } from "react";
import styled from "styled-components";

// Assets
import circle from "../assets/svg/button-circle.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PurchaseButton from "../components/PurchaseButton";


const MauticForm = () => {
  const [videoKey, setVideoKey] = useState(false);
  const [src, setSrc] = useState("./weloveyou-landscape.mp4"); 
  
  function lightbox_open() {
    const handler = e => {
        console.log("media query: " + e.matches)
        document.getElementById('light').style.display = 'block';
        document.getElementById('fade').style.display = 'block';
  
        if (e.matches) {
            console.log('Mobile matched...')
            setSrc('./weloveyou-portrait.mp4');
            setVideoKey(!videoKey);
            
        } else {
            console.log('Desktop matched...')
            setSrc('./weloveyou-landscape.mp4');
            setVideoKey(!videoKey);
        }

        var lightBoxVideo = document.getElementById("VisaChipCardVideo");
        console.log('Placying...')

        lightBoxVideo.play();

        setTimeout(() => {
          lightbox_close()
        }, 5000); 
        
        console.log('lightbox_open...')
  
    };
    handler(window.matchMedia("(max-width: 768px)"));
  
  }
  
  function lightbox_close() {
    var lightBoxVideo = document.getElementById("VisaChipCardVideo");
    let light = document.getElementById('light')
    let fade = document.getElementById('fade')
  
    if (light && fade && lightBoxVideo) {
      light.style.display = 'none';
      fade.style.display = 'none';
      lightBoxVideo.pause();
    }
  
    console.log('lightbox_close')
  
  }
  
  function sendEmail() {
  
    console.log('sendEmail...');
      
    var email = document.getElementById("mauticform_input_prelaunchoptin_email").value
    console.log("Clicked...email: " + email);
  
    const formData = new FormData()
    formData.append('mauticform[formId]', 2)
    formData.append('mauticform[messenger]', 1) // required
    formData.append('mauticform[email]', email)
  
    fetch('https://mautic.senzybee.com/form/submit', {
      method: 'POST',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: formData,
    })
    .then(response => response.text())
    .then(result => {
      console.log("The result: " + result)
      console.log("The result: " + JSON.stringify(result))
      if (result.includes('results')) {
        console.log('Thank you for a message');
        lightbox_open();
      } else {
        console.log('Error')
        toast.error("Failed to subscribe! Internal error");
      }
    })
    .catch(e => {
      console.log("Error happened..." + JSON.stringify(e))
      toast.error("Failed to subscribe! Please check your Internet connection!");
    });
  }

  function Clip({ url }) {
    console.log('Clip, url: ' + url)
    const videoRef = useRef();
    const previousUrl = useRef(url);
  
    useEffect(() => {
      if (previousUrl.current !== url && videoRef.current) {
        console.log('useEffect, load...url: ', url)
        videoRef.current.load();
        previousUrl.current = url;
      }
    }, [url]);
  
    return (
      <video ref={videoRef} id="VisaChipCardVideo" onClick={lightbox_close}
              preload="metadata"
              muted
              autoPlay
              playsInline
              >
        <source src={url} />
      </video>
    );
  }

  return (
    <MauticStyled>
      <div
        id="mauticform_wrapper_prelaunchoptin"
        className="mauticform_wrapper">
        {/* <div
          id="mauticform_prelaunchoptin_email"
          className="mauticform-row">
              <label>
                <div className="mautic-label">
                    Help us spread the word and be part of our development. Please sign
                    up to our newsletter.
                </div>
                
                <input
                  id="mauticform_input_prelaunchoptin_email"
                  name="mauticform[email]"
                  placeholder="Email address"
                  className="mauticform-input"
                  type="email"
                />
              </label>
        </div> */}
        {/* <button onClick={sendEmail}>  
          Subscribe
        </button> */}
        <div style={{display: "flex", justifyContent: "flex-center"}}>
          <PurchaseButton text="Get your SenzyBee" style={{color: "black"}}/>
        </div>
        <div id="fade" onClick={lightbox_close}></div>
        <div id="light">
          <Clip url={src} />
        </div>
        <ToastContainer />
      </div>
      
    </MauticStyled>
  );
};

const MauticStyled = styled.div`
  width: 100%;
  font-weight: 300;

  #VisaChipCardVideo {
    width: 100%;
    height: 100%;
  }

  #fade {
    display: none;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 1001;
    -moz-opacity: 0.8;
    opacity: .80;
    filter: alpha(opacity=80);
  }

  #light {
    display: none;
    position: fixed
    ;
    opacity: 1.0;


    width: 100%;
    height: auto;
    // top: calc(100vh -height)/2;
    // bottom: auto;
    top: 0;
    bottom: 0;
    left: 0%;
    max-width: 100%;

    border: 2px solid #FFF;
    background: #FFF;
    z-index: 1002;
    overflow: visible;
  }


  .mautic-label {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 2.4rem;
    color: #304972;

    @media (max-width: 768px) {
      font-size: 1.8rem;
    }
  }
  .mauticform-row {
    display: block;
    margin-bottom: 20px;
    width: 100%;

    @media (max-width: 768px) {
      width: 80%;
    }
  }

  .mauticform-row.mauticform-required .mauticform-label:after {
    color: #e32;
    content: " *";
    display: inline;
  }

  .mauticform-selectbox,
  .mauticform-input,
  .mauticform-textarea {
    width: 20%;
    padding: 0.5em 0.5em;
    border: 1px solid #ccc;
    background: #fff;
    box-shadow: 0px 0px 0px #fff inset;
    box-sizing: border-box;
  }

  # New
  .mauticform-button-wrapper {
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  button {
    background: url(${circle});
    background-size: contain;
    background-repeat: no-repeat;
    border: none;
    font-size: 3.2rem;
    color: #304972;
    font-weight: 300;
    margin-left: 3rem;
    color: #304972;
    max-width: 70%;
    float: right;

    @media (max-width: 768px) {
      font-size: 2rem;
      margin-left: 1.6rem;
    }

    @media (max-width: 330px) {
      float: right;
    }
  }
`;

export default MauticForm;
