import React, { useState } from 'react';
import ImageContext from '../components/ImageContext';
import { HashLink } from "react-router-hash-link";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const PurchaseButton = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log("PurchaseButton...")
  const style = props.style || {color: "white", background: "#d4dde0"};

  const openModal = () => {
    console.log("openModal...")
    setIsModalOpen(true);
  }
  const closeModal = () => setIsModalOpen(false);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ImageContext.Consumer>
      {
        imgCtx => (
          <div>
            <HashLink to={'/purchase' + location.search}>
              <button style={style}>{props.text}</button>
            </HashLink>
          </div>
        )
      }
    </ImageContext.Consumer>
  );
};

export default PurchaseButton;