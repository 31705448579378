import React, { Component } from 'react';
import ImageContext from './ImageContext';

import empty from "../assets/png/empty.png";

// first we will make a new context
// const ImageContext = React.createContext();

// Then create a provider Component
class ImageProvider extends Component {
    
    comparator = {
        '<': function(a, b) { return a < b; },
        '<=': function(a, b) { return a <= b; },
        '>': function(a, b) { return a > b; },
        '>=': function(a, b) { return a >= b; }
    };
    state = {
        webp: false,
        firstImages: null,
        firstReady: false,
        flyingImages: null,
        flyReady: false,
        blueImages: null,
        blueReady: false,
        lastImages: null,
        lastReady: false,
    }

    vendor;
    userAgent;

    chrome(range) {
        var match = /google inc/.test(this.vendor) ? this.this.userAgent.match(/(?:chrome|crios)\/(\d+)/) : null;
        return match !== null && !this.opera() && this.compareVersion(match[1], range);
    };

    edge(range) {
        var match = this.userAgent.match(/edge\/(\d+)/);
        return match !== null && this.compareVersion(match[1], range);
    };

    firefox(range) {
        var match = this.userAgent.match(/(?:firefox|fxios)\/(\d+)/);
        return match !== null && this.compareVersion(match[1], range);
    };

    opera(range) {
        var match = this.userAgent.match(/(?:^opera.+?version|opr)\/(\d+)/);
        return match !== null && this.compareVersion(match[1], range);
    };

    operaMini(range) {
        var match = this.userAgent.match(/opera mini\/(\d+)/);
        return match !== null && this.compareVersion(match[1], range);
    };

    safari(range) {
        var match = this.userAgent.match(/version\/(\d+).+?safari/);
        return match !== null && this.compareVersion(match[1], range);
    };

    iphone(range) {
        // avoid false positive for Facebook in-app browser on ipad;
        // original iphone doesn't have the OS portion of the UA
        var match = this.ipad() ? null : this.userAgent.match(/iphone(?:.+?os (\d+))?/);
        return match !== null && this.compareVersion(match[1] || 1, range);
    };

    ipod(range) {
        var match = this.userAgent.match(/ipod.+?os (\d+)/);
        return match !== null && this.compareVersion(match[1], range);
    };

    ipad(range) {
        var match = this.userAgent.match(/ipad.+?os (\d+)/);
        return match !== null && this.compareVersion(match[1], range);
    };

    ios() {
        return this.iphone() || this.ipad() || this.ipod();
    }

    android() {
        return /android/.test(this.userAgent) || /Android/.test(this.userAgent);
    }
    
    compareVersion(version, range) {
        var string = (range + '');
        var n = +(string.match(/\d+/) || NaN);
        var op = string.match(/^[<>]=?|/)[0];
        return this.comparator[op] ? this.comparator[op](version, n) : (version === n);
    }

    isWebpSupported() {

        return this.android() ||
               (this.ios() && this.safari('>=14.4')) ||
               (!this.ios() && (
                   this.chrome('>=32') ||
                   this.firefox('>=65') ||
                   this.opera('>=19') ||
                   this.operaMini()
               ));
    }

    componentDidMount() {
        this.userAgent = navigator.userAgent
        this.vendor = navigator.vendor
        var webpSupported = this.isWebpSupported()
        this.setState({webp: webpSupported});
        this.start(webpSupported);
    }

    start(webpSupported) {
        this.fetchFirst(webpSupported);
    }

    fetchFirst(isWebp) {
        var blueStarted = false;
        var blueStart = setTimeout(() => {
            blueStarted = true;
            this.fetchFly(isWebp);
        }, 1000);


        fetch(
            'jsonFirstPng.json',
            {
            headers : { 
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, compress, br',
                'Accept': 'application/json'
            }
            }
        )
        .then((response) => {
            return response.json();
        })
        .then((myJson) => {
            this.setState({firstImages: myJson, firstReady: true});
            if (blueStarted === false) {
                window.clearTimeout(blueStart);
                console.log('calling fly json...');
                this.fetchFly(isWebp);
            }
        });
    }
    
    fetchFly(isWebp) {
        var blueStarted = false;
        var blueStart = setTimeout(() => {
            console.log('starting blue json...');
            blueStarted = true;
            this.fetchBlue(isWebp);
        }, 4000);

        console.log("Fetching isWebp: " + isWebp);


        fetch(
            isWebp ? 'jsonFlyWebp.json' : 'jsonFlyPng.json',
            {
            headers : { 
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, compress, br',
                'Accept': 'application/json'
            }
            }
        )
        .then((response) => {
            console.log("fly response...")
            return response.json();
        })
        .then((myJson) => {
            console.log("flyJson...");
            this.setState({flyingImages: myJson, flyReady: true, order: myJson.order1.concat(myJson.order2)});
            console.log('loaded fly json...');

            if (blueStarted === false) {
                window.clearTimeout(blueStart);
                console.log('calling blue json...');
                this.fetchBlue(isWebp);
            }
        });
    }

    fetchBlue(isWebp) {
        var blueStarted = false;
        var blueStart = setTimeout(() => {
            blueStarted = true;
            this.fetchLast(isWebp);
        }, 2000);


        fetch(
            isWebp ? 'jsonBlueWebp.json' : 'jsonBluePng.json',
            {
            headers : { 
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, compress, br',
                'Accept': 'application/json'
            }
            }
        )
        .then((response) => {
            console.log("blue response...")
            console.log(response)
            return response.json();
        })
        .then((myJson) => {
            console.log("blue Json...");
            // console.log(myJson);
            this.setState({blueImages: myJson});
            // this.state.blueImages = myJson;
            console.log('loaded blue json...');
            // this.state.blueReady = true;
            
            this.setState({
                blueReady: true
            })

            if (blueStarted === false) {
                window.clearTimeout(blueStart);
                console.log('calling last json...');
                this.fetchLast(isWebp);
            }
        });

    }

    fetchLast(isWebp) {
        fetch(
            isWebp ? 'jsonLastWebp.json' : 'jsonLastPng.json',
            {
            headers : { 
                'Content-Type': 'application/json',
                'Accept-Encoding': 'gzip, compress, br',
                'Accept': 'application/json'
            }
            }
        )
        .then((response) => {
            console.log("fly response...")
            // console.log(response)
            return response.json();
        })
        .then((myJson) => {
            console.log("jsonLast...");
            this.setState({lastImages: myJson, lastReady: true});
            console.log('loaded last json...');
        });
    }

    getFly(index) {
        if (this.state.flyReady) {
            if (index < this.state.order.length) {
                return this.state.flyingImages[this.state.order[index]];
            } else {
                return empty;
            }
        }
        return empty;
    }

    getFlyIndex(index) {
        if (this.state.flyReady) {
            return this.state.order[index];
        }
        return -1;
    }

    getFlyMax() {
        if (this.state.flyReady) {
            return this.state.order.length;
        }
        
        return 0;
    }

    getOrder1Length() {
        if (this.state.flyReady) {
            return this.state.flyingImages["order1"].length;
        }
    }

    getOrder2Length() {
        if (this.state.flyReady) {
            return this.state.flyingImages["order2"].length;
        }
    }

    getBlue(key) {
        if (this.state.blueReady) {
            return this.state.blueImages[key];
        }
        return empty;
    }

    getFirst(key) {
        if (this.state.firstReady) {
            // console.log("getFirst, index: " + key);
            return this.state.firstImages[key];
        }
        // console.log("getFirst, default...");
        return empty;
    }

    getLast(key) {

        if (key === "vibration.svg") {
            console.log("vibration.svg: ")
            if (this.state.lastReady) {
                console.log("getLast, index: " + key);
                return this.state.lastImages[key];
            } else {
                console.log("Not ready (last)...");
            }
        } else {
            if (this.state.lastReady) {
                // console.log("getLast, index: " + key);
                return this.state.lastImages[key];
            }
            // console.log("getLast, default...");
        }
        
        return empty;
    }

    

  render() {
    return (
      <ImageContext.Provider value={{
        state: this.state,
        getFirst: this.getFirst,
        getFly: this.getFly,
        getFlyIndex: this.getFlyIndex,
        getFlyMax: this.getFlyMax,
        getOrder1Length: this.getOrder1Length,
        getOrder2Length: this.getOrder2Length,
        getBlue: this.getBlue,
        getLast: this.getLast,
        setWebp: (supported) => this.setState({
            webp: supported
        }),
        setFlyingImage: (images) => this.setState({
            flyingImages: images
        }),
        setFlyReady: (ready) => this.setState({
            flyReady: ready
        }),
        setBlueImage: (images) => this.setState({
            blueImages: images
        }),
        setBlueReady: (ready) => this.setState({
            blueReady: ready
        })
      }}>
        {this.props.children}
      </ImageContext.Provider>
    )
  }
}

export default ImageProvider;