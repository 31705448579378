// Import dependencies
import styled from "styled-components";
import { useEffect } from "react";

const ThirdContentSection = () => {

  const getOpacity = (pos, start, end) => {
    if (pos < start) {
      return 0;
    } else if (pos > end) {
      return 15;
    } else {
      var iv = end - start;
      return ((pos - start) / iv) * 15;
    }
  }

  var lastScroll = 0;

  useEffect(() => {

    document.addEventListener("scroll", () => {
      let actualScroll = window.scrollY;
      if (Math.abs(lastScroll - actualScroll) < 5) {
        // console.log("last Scroll quit...")
        return;
      }
      lastScroll = actualScroll;

      var secondElem = document.getElementById("secondContent");
      var thirdElem = document.getElementById("thirdContent");
      var homeElem = document.getElementById("home");
      var youcanElem = document.getElementById("youcan");
      var butwhyElem = document.getElementById("butwhy");

      if (secondElem && thirdElem && homeElem && youcanElem && butwhyElem) {
        // console.log("actualScroll: " + actualScroll)
        
        var second10 = thirdElem.offsetHeight * 0.1;
        var second60 = thirdElem.offsetHeight * 0.6;


        var youcanTop = youcanElem.getBoundingClientRect().top;
        
        var butwhyTop = butwhyElem.getBoundingClientRect().top;

        var opac = getOpacity(youcanTop, second10, second60);
        youcanElem.style.filter = `blur(${opac}px)`

        var opac2 = getOpacity(butwhyTop, second10, second60);
        butwhyElem.style.filter = `blur(${opac2}px)`

      }

      

    })

  }, []);

  return (
    <Content id="thirdContent">
      <div id="youcan">
      {/* <div id="youcan" className="left"> */}
        You can already see and hear him from a distance…
      </div>

      <div id="butwhy" className="right">…but why live without feeling his touch?</div>
    </Content>
  );
};

const Content = styled.div`
  font-size: 4.8rem;
  // font-size: 9.6rem;
  color: #304972;

  padding: 8.5rem 3rem;
  // padding: 0rem 0rem 14.2rem 2rem;

  font-weight: 300;
  line-height: 125%;
  text-transform: uppercase;
  width: 100%;
  box-sizing: border-box;
  margin-top: auto;
  margin-bottom: auto;
  background: #fff;





  display: flex;
  display: -webkit-flex;

  align-items: center;
  -webkit-align-items: center;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: center;
  -webkit-justify-content: center;

  height: 100vh;

  @media (max-width: 768px) {
    font-size: 3.6rem;
    padding: 16.8rem 2rem;
  }

  .right {
    text-align: right;
    margin-top: 7.5rem;
    filter: blur(15px);
  }
`;

export default ThirdContentSection;
