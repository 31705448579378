// Import dependencies
import { CookieBanner } from '@palmabit/react-cookie-law';

const CookieBar = () => {
  
  return (
    <CookieBanner
      message={"We use cookies to personalise content, " +
      "to provide social media features and to analyse our traffic. "}
      
      policyLink="https://senzybee.com/privacy-policy.html"

      showPreferencesOption={false}
      statisticsDefaultChecked={true}
      marketingDefaultChecked={true}

      styles={{
        checkbox: { backgroundColor: '#b8b6b2', color: '#b8b6b2' }
      }}

      

      wholeDomain={true}
      onAccept = {() => {}}
      onAcceptPreferences = {() => {}}
      onAcceptStatistics = {() => {}}
      onAcceptMarketing = {() => {}}
    />
  );
};

export default CookieBar;
